// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React from 'react';
import {Text, View, StyleSheet, Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';

// services & config
import {appConfig, theme} from '../../config';
import translate from '../../services/localization';
import AppIntroSlider from '../../components/shared/AppIntroSlider';
import Icon from "react-native-vector-icons/Ionicons";
import {ScrollIndicatorWrapper} from "../../components/shared";
import DeviceInfo from "react-native-device-info";

/***********************************************************************************************
 * component
 ***********************************************************************************************/
function WalkthroughView({ onDone, onSkip }) {

  let _renderItem = ({ item }) => {
    return (
        <View style={{ paddingBottom: appConfig.scaleUiFkt(75) * (DeviceInfo.isTablet() ? 0.5 : 1), height: '100%' }}>
      <ScrollIndicatorWrapper>
        <View style={localStyle.slide}>
          <Text style={localStyle.title}>{item.title}</Text>
          {!!item.image && <Image source={item.image.uri} style={localStyle.image} />}
          <Text style={localStyle.text}>{item.text}</Text>
        </View>
      </ScrollIndicatorWrapper>
        </View>
    );
  }

  let _renderNextButton = () => {
    return (
        <View style={localStyle.buttonCircle}>
          <Icon
              raised
              name="arrow-forward"
              color={theme.colors.success}
              size={24}
          />
        </View>
    );
  };
  let _renderSkipButton = () => {
    return (
        <View>
          <Text style={localStyle.skipText}>Überspringen</Text>
        </View>
    );
  };

  let _renderDoneButton = () => {
    return (
        <View>
          <TouchableOpacity
              style={localStyle.button}
              onPress={onDone}
              accessibilityLabel={translate('login').landing.buttonText}
              accessibilityRole={translate('accessibility').types.button}
              accessibilityHint={translate('accessibility').loginHint}
          >
            <Text style={localStyle.buttonText}>Fertig</Text>
          </TouchableOpacity>
        </View>
    );
  };

  const slides = [
    {
      key: 'k1',
      title: translate('walkthrough').k1.title,
      text: translate('walkthrough').k1.text,
      image: {
        uri:
            require('src/assets/images/questionnaire.png'),
      },
      titleStyle: localStyle.title,
      textStyle: localStyle.text,
      backgroundColor: theme.colors.primary,
    },
    {
      key: 'k2',
      title: translate('walkthrough').k2.title,
      text: translate('walkthrough').k2.text,
      image: {
        uri:
            require('src/assets/images/questionnaire.png'),
      },
      titleStyle: localStyle.title,
      textStyle: localStyle.text,
      backgroundColor: theme.colors.primary,
    },
    // {
    //   key: 'k3',
    //   title: translate('walkthrough').k3.title,
    //   text: translate('walkthrough').k3.text,
    //   image: {
    //     uri: require('src/assets/images/questionnaire.png'),
    //   },
    //   titleStyle: localStyle.title,
    //   textStyle: localStyle.text,
    //   backgroundColor: theme.colors.primary,
    // },
    {
      key: 'k4',
      title: translate('walkthrough').k4.title,
      text: translate('walkthrough').k4.text,

      titleStyle: localStyle.title,
      textStyle: localStyle.text,
      imageStyle: localStyle.image,
      backgroundColor: theme.colors.primary,
    }
  ];

  return (
      <AppIntroSlider data={slides}
                      renderItem={_renderItem}
                      showSkipButton={true}
                      renderNextButton={_renderNextButton}
                      renderSkipButton={_renderSkipButton}
                      renderDoneButton={_renderDoneButton}
                      onSkip={onSkip}
                      dotStyle={{backgroundColor: 'rgba(0, 0, 0, .2)'}}
                      activeDotStyle={{backgroundColor: theme.colors.primary}}

      />
  );
}

WalkthroughView.propTypes = {
  onDone: PropTypes.func,
  onSkip: PropTypes.func
};

WalkthroughView.defaultProps = { onDone: () => {}, onSkip: () => {} };

/***********************************************************************************************
local styling
***********************************************************************************************/

const localStyle = StyleSheet.create({

  MainContainer: {
    flex: 1,
    paddingTop: appConfig.scaleUiFkt(20),
    alignItems: 'center',
    justifyContent: 'center',
    padding: appConfig.scaleUiFkt(20)
  },
  title: {
    fontSize: theme.fonts.title.fontSize,
    color: theme.colors.success,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: appConfig.scaleUiFkt(20),
  },
  text: {
    color: theme.colors.accent4,
    fontSize: theme.fonts.header2.fontSize,
    marginTop: appConfig.scaleUiFkt(15),
    // textAlign: "justify",
    backgroundColor: theme.colors.accent2,
    padding: appConfig.scaleUiFkt(15)
  },
  skipText: {
    color: theme.colors.success,
    fontFamily: theme.fonts.header3.fontFamily,
    fontSize: theme.fonts.header3.fontSize,
    marginTop: 8
  },
  buttonText: {
    color: theme.colors.success,
    fontSize: theme.fonts.header2.fontSize,
  },
  image: {
    width: '100%',
    height: 150,
    marginTop: appConfig.scaleUiFkt(15),
    marginBottom: appConfig.scaleUiFkt(15),
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  slide: {
    paddingStart: appConfig.scaleUiFkt(15),
    paddingEnd: appConfig.scaleUiFkt(15),
    marginBottom: appConfig.scaleUiFkt(75)
  },
  buttonCircle: {
    width: appConfig.scaleUiFkt(40) * (DeviceInfo.isTablet() ? 0.7 : 1),
    height: appConfig.scaleUiFkt(40) * (DeviceInfo.isTablet() ? 0.7 : 1),
    backgroundColor: theme.colors.primary,
    borderRadius: appConfig.scaleUiFkt(20) * (DeviceInfo.isTablet() ? 0.7 : 1),
    marginTop: (DeviceInfo.isTablet() ? -30 : 0),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default WalkthroughView;
