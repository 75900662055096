/**********************************************************************************
import
***********************************************************************************************/

import React, {useState} from 'react';
import {View, StyleSheet, Modal, Alert, Pressable, Text, useWindowDimensions, Platform} from 'react-native';
import PropTypes from 'prop-types';

import { theme } from '../../config';
import RenderHtml from "react-native-render-html";
import {IconButton} from "react-native-paper";
import {markdownToHtml} from "../../services/utils";

function ModalInfo({ infoText }) {

  const [modalVisible, setModalVisible] = useState(false);
  const { width } = useWindowDimensions();

  const source = {
    html: '' + markdownToHtml(infoText) + ''
  }

  return (
      <View style={styles.centeredView}>
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {Platform.OS === 'web' ? (<div
                dangerouslySetInnerHTML={{__html: source.html}}
              />) :
                (<RenderHtml
                    contentWidth={width}
                    source={source}
                />)
              }
              <Pressable
                  style={[styles.button, styles.buttonClose]}
                  onPress={() => setModalVisible(!modalVisible)}>

                <IconButton
                    icon="close"
                    iconColor={'black'}
                    size={22}
                    mode={"contained"}
                    style={{marginRight: 10}}
                />
                <Text style={[styles.textStyle, { marginRight: 10, fontSize: 15}]}>Schließen</Text>
              </Pressable>
            </View>
          </View>
        </Modal>

        <Pressable
            style={[styles.button, styles.buttonOpen]}
            onPress={() => setModalVisible(true)}>
          <IconButton
              icon="information"
              iconColor={'black'}
              size={22}
              mode={"contained"}
              style={{marginRight: 10}}
          />
          <Text style={[styles.textStyle, { marginRight: 10, fontSize: 15}]}>Zusätzliche Erklärung anzeigen</Text>
        </Pressable>
      </View>
  );
}

ModalInfo.propTypes = {
  infoText: PropTypes.string.isRequired,
};

/***********************************************************************************************
local styling
***********************************************************************************************/

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    flexDirection: "row",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 10,
    padding: 5,
  },
  buttonOpen: {
    backgroundColor: theme.colors.primary
  },
  buttonClose: {
    backgroundColor: theme.colors.primary,
    marginTop: 15
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default ModalInfo;
