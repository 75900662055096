import * as React from 'react';
import {Text} from 'react-native-paper';
import {Modal, StyleSheet, View} from "react-native";
import RoundedButton from "./RoundedButton";

const InfoDialog = ({navigation, dialogVisible, setDialogVisible, title, text}) => (
    <Modal visible={dialogVisible} onDismiss={() => {
        setDialogVisible(false)
    }}
    >
        <View style={styles.modal}>
            <View style={{flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30}}>


                <Text style={{fontSize: 20}}>
                    {title}{"\n"}
                </Text>

                <Text>
                    {text}
                </Text>


            </View>
            <View style={{flexDirection: 'column', flexWrap: 'wrap', marginBottom: 30, alignItems: 'flex-end'}}>
                <RoundedButton onPress={() => {
                    setDialogVisible(false)
                }} text={'OK'}/>
            </View>
        </View>
    </Modal>
);

const styles = StyleSheet.create({
    modal: {
        padding: 20,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
    }
});

export default InfoDialog;
