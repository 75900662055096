// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React, { useEffect } from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet, Platform} from 'react-native';
import AppIntroSlider from '../components/shared/AppIntroSlider';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// custom components
import { Spinner, Banner, ScrollIndicatorWrapper } from '../components/shared';

// services & config
import { appConfig, theme } from '../config';
import translate from '../services/localization';

import { Routes, Stacks } from '../navigation/constants';
import WalkthroughView from "../components/checkIn/walkthroughView";
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component:
 * renders the landing screen with the welcome message an a button
 * which redirects to the login screen
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 **********************************************************************************************/
function LandingScreen({ navigation }) {
  const dispatch = useDispatch();

  // get date from state
  const { loading } = useSelector((state) => state.Globals);
  const { subjectId } = useSelector((state) => state.User);

  // when component loads handle log in
  useEffect(() => {
    // navigate to  'checkInScreen' if login was successful
    if (subjectId) {
      navigation.replace(Stacks.SIGNED_IN, { screen: Routes.CHECK_IN });
    }
  }, [dispatch, subjectId, navigation]);

  // rendering
  /*-----------------------------------------------------------------------------------*/

  // checks the currently selected route
  return loading ? (
    <></>
  ) : (
      <View style={localStyle.wrapper}>
        {/* banner */}
        <Banner
            nav={navigation}
            title={translate('login').landing.title}
            subTitle={translate('login').landing.subTitle}
            noWayBack
            simpleAbout={true}
        />
        <WalkthroughView
            onSkip={() => {navigation.navigate(Routes.LOGIN)}}
            onDone={() => {navigation.navigate(Routes.LOGIN)}}
        />
      </View>
  );
}

LandingScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
localStyle
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    height: '100%',
    width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },

  top: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: appConfig.scaleUiFkt(35),
    marginBottom: appConfig.scaleUiFkt(35),
  },

  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 36,
    height: '100%',
    marginTop: 20,
  },

  infoText: {
    marginVertical: appConfig.scaleUiFkt(20),
    marginHorizontal: appConfig.scaleUiFkt(40),
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.colors.accent4,
    ...theme.fonts.body,
  },

  titleText: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
    ...theme.fonts.header2,
  },

  button: {
    ...theme.classes.buttonPrimary,
    bottom: 0,
    paddingVertical: appConfig.scaleUiFkt(15),
    paddingHorizontal: appConfig.scaleUiFkt(15),

  },

  buttonLabel: {
    ...theme.classes.buttonLabel,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default LandingScreen;
