import React from 'react';
import {I18nManager, StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

// components
import { Button, Icon } from '@rneui/themed';

// redux actions
import {switchContent} from '../../store/questionnaire.slice';

// services & config
import setAccessibilityResponder from '../../services/setAccessibilityResponder';
import questionnaireAnalyzer from '../../services/questionnaireAnalyzer';
import translate from '../../services/localization';
import {appConfig, theme} from '../../config';

// custom components
import ProgressBar from './progressbar';
import {reset} from "../../store/sharedActions";

/***********************************************************************************************
 * component
 * creates the bottom-navigation-bar of the modal
 *
 * @param {object}                props the props of this component
 * @param {function}              props.hideModal a callback to hide the modal
 * @param {React.RefObject<any>}  props.modalTitleRef a reference object of the modal title for a11y purposes
 * @param {function}              props.handleScrollTo a callback to scroll to the top when switching between pages
 **********************************************************************************************/
export default function BottomBar({
                                      modalTitleRef,
                                      handleScrollTo,
                                      handleForwardPress
                                  }) {
    const dispatch = useDispatch();

    // get data from state

    const {pageIndex, categoryIndex, itemMap, categories} = useSelector(
        (state) => state.Questionnaire,
    );

    // check whether the current page has been completely answered
    const completed =
        itemMap[categories[categoryIndex].item[pageIndex - 1].linkId].done;

    const required =
        itemMap[categories[categoryIndex].item[pageIndex - 1].linkId].required;

    const totalTime = questionnaireAnalyzer.calculateTotalTime(categories, categoryIndex);

    /**
     * handler for the 'back' button
     */
    const handleBackPress = () => {
        setAccessibilityResponder(modalTitleRef);
        let index = pageIndex - 1;
        while (index >= 0) {
            if (
                questionnaireAnalyzer.checkConditionsOfSingleItem(
                    categories[categoryIndex].item[index - 1],
                    itemMap,
                ) && !questionnaireAnalyzer.itemIsEmbedded(
                    categories[categoryIndex].item[index - 1],
                    itemMap,
                )
            ) {
                dispatch(switchContent({pageIndex: index}));
                return handleScrollTo({y: 0, animated: false});
            }
            index -= 1;
        }
    };

    const progress = () => {
        return appConfig.useStrictModeProgressBar
            ? questionnaireAnalyzer.calculatePageProgress(
                categories,
                categoryIndex,
                pageIndex,
                itemMap,
            )
            : pageIndex / categories[categoryIndex].item.length
    }

    const etl = () => {
        console.log(progress())
        console.log(1 - progress())
        console.log(totalTime)
        return Math.round((1 - progress()) * totalTime);
    }

    const getTitle = () => {
        if (categories[categoryIndex].item[pageIndex - 1]?.fieldAnnotation?.includes('[stop]')) {
            return 'Schließen';
        }

        return !categories[categoryIndex].item[pageIndex - 1]?.fieldAnnotation?.includes('[submitsingle]') ? "Weiter" : 'Abschicken';
    }
    return (
        <View
            style={
                appConfig.useProgressBar
                    ? localStyles.bottomBarWrapper
                    : localStyles.bottomBarWrapperWithShadow
            }
            testID="BottomBar"
        >

            {/*{required &&*/}
            {/*    <Text style={[{ marginStart: 10, color: 'red', fontWeight: 'bold', marginBottom: 10 }]}>(Pflichtfrage)</Text>}*/}


            <View style={localStyles.bottomBarButtons}>
                {/* the left navigational button; hidden if on page 1 */}
                <View>
                    <Button
                        type="clear"
                        disabled={pageIndex === 1}
                        disabledStyle={localStyles.disabledButton}
                        accessibilityLabel={translate('accessibility').back}
                        accessibilityRole={translate('accessibility').types.button}
                        accessibilityHint={
                            translate('accessibility').questionnaire.leftButtonHint
                        }
                        onPress={handleBackPress}
                        style={localStyles.modalPaginationButton}
                        icon={
                            <Icon
                                name={I18nManager.isRTL ? 'arrow-right' : 'arrow-left'}
                                type="material-community"
                                color={theme.colors.accent4}
                            />
                        }
                        testID="BottomBar_back_btn"
                    />
                    {pageIndex !== 1 &&
                        <Text>Zurück</Text>}
                </View>

                {appConfig.useProgressBar && (
                    <View style={{width: 100, alignItems: 'center'}}>
                        <ProgressBar
                            progress={progress()}
                        />
                        {!categories[categoryIndex].item[0]?.fieldAnnotation?.includes('[hidepagination]') && (
                            <>
                                {!totalTime && (
                                    <Text
                                        style={{fontSize: 10}}>Seite {pageIndex} / {categories[categoryIndex].item.length}</Text>
                                )}
                                {!!totalTime && (
                                    <Text style={{fontSize: 10}}>Verbleibende Zeit: {etl()} Min.</Text>
                                )}
                                <Text style={{fontSize: 10}}>
                                    Fragebogen {categoryIndex + 1} / {categories.length}
                                </Text>
                            </>
                        )}
                    </View>
                )}

                {/* the confirmation button in the middle - its color depends on checkCurrentPageState() */}
                <Button
                    type="clear"
                    title={getTitle()}
                    // disabled={!completed && required}
                    titleStyle={!completed && required ? localStyles.completeButtonTitleDisabled : localStyles.completeButtonTitle}
                    accessibilityLabel={
                        !completed && required
                            ? translate('accessibility').questionnaire.middleButtonUnfinished
                            : translate('accessibility').questionnaire.middleButtonFinished
                    }
                    accessibilityRole={translate('accessibility').types.button}
                    accessibilityHint={
                        translate('accessibility').questionnaire.middleButtonHint
                    }
                    onPress={handleForwardPress}
                    icon={
                        <Icon
                            name="check"
                            reverse
                            type="material-community"
                            color={!completed && required ? theme.colors.accent4 : theme.colors.yes}
                        />
                    }
                    testID="BottomBar_confirm_btn"
                />
            </View>
        </View>
    );
}

BottomBar.propTypes = {
    modalTitleRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.shape({})}),
    ]).isRequired,
    handleScrollTo: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyles = StyleSheet.create({
    bottomBarWrapper: {
        backgroundColor: theme.values.defaultModalBottomBarBackgroundColor,
    },

    bottomBarWrapperWithShadow: {
        backgroundColor: theme.values.defaultModalBottomBarBackgroundColor,
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 15,
    },

    bottomBarButtons: {
        borderColor: theme.colors.primary,
        backgroundColor: theme.colors.primary_very_light,
        borderTopWidth: 1,
        flexWrap: 'nowrap',
        alignItems: 'center',
        alignContent: 'flex-end',
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingStart: 20,
        paddingEnd: 20,
        paddingBottom: 20
    },

    disabledButton: {
        opacity: 0,
    },

    modalPaginationButton: {
        width: 44,
        height: 44,
        position: 'relative'
    },

    completeButtonTitle: {
        fontSize: 20,
        color: theme.values.defaultSurveyIconCompletedColor
    },

    completeButtonTitleDisabled: {
        fontSize: 20,
        color: theme.colors.accent1
    },
});
