/* eslint-disable lines-around-directive */
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {Dimensions, Platform, SafeAreaView, StyleSheet, Text, TouchableOpacity, View,} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// components
import {useIsFocused} from '@react-navigation/native';
import NetInfo from '@react-native-community/netinfo';

// custom components
import {Banner} from '../components/shared';
import {Routes, Stacks} from '../navigation/constants';
import QRCodeScanner from '../components/qrcode/QRCodeScanner';

// redux actions
import {sendCredentials} from '../store/user.slice';

// services & config
import {appConfig, theme} from '../config';
import translate from '../services/localization';
import endpoints from '../services/rest/endpoints';
import {Button, Icon, Input} from '@rneui/themed';
import {isMobile} from "react-device-detect";

/**
 * tries to parse the input-string and returns the subjectId (from the qr-code)
 * @param  {string} str string to be checked
 * @returns {string}
 */
const checkQrCodeForUsername = (str) => {
    let subjectId;
    try {
        const qrCode = JSON.parse(str);
        if (
            qrCode[appConfig.qrCodeAttributeHoldingTheAppIdentifier] ===
            appConfig.appIdentifier.name ||
            qrCode[appConfig.qrCodeAttributeHoldingTheAppIdentifier] ===
            appConfig.alternativeAppIdentifier.name
        ) {
            subjectId = qrCode[appConfig.qrCodeAttributeHoldingTheSubjectId];
        }
    } catch (e) {
        return '';
    }
    // returns the id or an empty string if no id could be found
    return subjectId || '';
};

/***********************************************************************************************
 * component:
 * renders the login-screen with the qr code scanner
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function LoginScreen({navigation}) {
    const dispatch = useDispatch();
    const isFocused = useIsFocused();

    const [isReachable, setIsReachable] = useState(false);
    const [manualSubjectID, setManualSubjectID] = useState('');

    const [qrCodes, setQrCodes] = useState([]);
    const [videoStream, setVideoStream] = useState(null);

    NetInfo.configure({
        reachabilityUrl: endpoints.ping,
        reachabilityTest: async (response) => response.status === 204,
        reachabilityLongTimeout: 60 * 1000, // 60s
        reachabilityShortTimeout: 5 * 1000, // 5s
        reachabilityRequestTimeout: 3 * 1000, // 15s
        reachabilityShouldRun: () => true,
        shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
        useNativeReachability: false,
    });

    NetInfo.fetch().then((state) => {
        // console.log("Connection type", state.type);
        // console.log("Is connected?", state.isConnected);
        // TODO: reset the setIsReachable to state.isInternetReachable, just had to use true now
        // because base url where ping is tested isn't available right now (https://api.umfragetool.digital-medicine.org/api/')
        setIsReachable(true);
    });

    const {subjectId} = useSelector((state) => state.User);
    const {error} = useSelector((state) => state.Globals);
    const [hasPermission, setHasPermission] = useState(false);

    // trigger login when barcode was detected
    useEffect(() => {
        if (qrCodes.length) {
            console.log('QR Code: ', qrCodes[qrCodes.length - 1]);
            // parses the input string to determine the subjectId (from the qr-code)
            const scannedSubjectId = checkQrCodeForUsername(qrCodes[0].toString());
            console.log('Scanned Subject ID: ', scannedSubjectId);

            // triggers the login if subjectId could be found
            if (scannedSubjectId) {
                dispatch(sendCredentials(scannedSubjectId));
            } else {
                console.log('No subject ID found in QR code');
            }
        }
    }, [qrCodes, dispatch]);

    useEffect(() => {
        if (subjectId) {
            // stops the video stream once the user has logged in successfully
            if (videoStream) {
                videoStream.getTracks().forEach((track) => track.stop());
            }
            return navigation.replace(Stacks.SIGNED_IN, {
                screen: Routes.CHECK_IN,
            });
        }
    }, [dispatch, subjectId, navigation]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (!!id) {
            dispatch(sendCredentials(id));
        }
    }, []);

    // rendering
    /*-----------------------------------------------------------------------------------*/

    // return (
    //     <View testID="LoginScreen" style={{ width: 1000, backgroundColor: '#aaaaaa' }}>
    //       <Banner
    //           nav={navigation}
    //           title={translate('login').title}
    //           subTitle={translate('login').subTitle}
    //           noMenu
    //       />
    //     </View>
    //   );

    return (

        <View testID="LoginScreen" style={localStyle.wrapper}>
            {/* banner */}
            <Banner
                nav={navigation}
                title={translate('login').title}
                subTitle={translate('login').subTitle}
                noMenu
                isCheckIn={true}
                noWayBack={true}
            />
            <View
                style={{
                    backgroundColor: theme.values.defaultBackgroundColor,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderColor: theme.values.defaultSeparatorColor,
                    alignItems: 'center',
                }}
            >
                {/*<Text style={[localStyle.infoText]}>*/}
                {/*    Falls Sie statt der App das Web-Portal nutzen möchten, können Sie sich*/}
                {/*    hier anmelden.*/}
                {/*</Text>*/}
            </View>
            {isReachable && (
                <View
                    style={{
                        backgroundColor: theme.values.defaultBackgroundColor,
                        paddingTop: 10,
                        borderColor: theme.values.defaultSeparatorColor,
                        alignItems: 'center',
                    }}
                >
                    <Text style={localStyle.infoText}>
                        Bitte geben Sie Ihren Zugangscode in das Eingabefeld ein.{'\n'}Betätigen
                        Sie anschließend auf den "Anmelden"-Button.
                    </Text>
                    <View style={{marginTop: 20}}>
                        <Input
                            style={{width: Platform.OS === 'web' && isMobile ? '90%' : 400}}
                            placeholder={'Eingabe Zugangscode'}
                            onChangeText={(value) => setManualSubjectID(value)}
                        />
                    </View>
                    <View style={{alignItems: 'flex-start'}}>
                        <Button
                            type="clear"
                            disabled={!manualSubjectID}
                            onPress={() => {
                                if (manualSubjectID != '') {
                                    dispatch(sendCredentials(manualSubjectID));
                                }
                            }}
                            title={'Anmelden'}
                            testID="redirectBtn"
                            icon={
                                <Icon
                                    name="check"
                                    reverse
                                    type="material-community"
                                    color={theme.colors.primary}
                                />
                            }
                        >
                            Anmelden
                        </Button>
                    </View>
                    <Text style={[localStyle.infoText, {marginTop: 10, marginBottom: 10}]}>
                        Alternativ können Sie auch den Zugangs-QR-Code mit Ihrer Webcam einscannen, falls Sie einen QR-Code von uns erhalten haben und Ihr Rechner über eine Kamera verfügt:{' '}
                    </Text>
                    <SafeAreaView style={localStyle.streamView}>
                        <QRCodeScanner
                            setQRCodes={setQrCodes}
                            setVideoStream={setVideoStream}
                        />
                    </SafeAreaView>


                    <Text style={{marginTop: 10, textAlign: 'center'}}>
                        App-Entwicklung: Institut für Digitale Medizin,
                        Universitätsklinikum Bonn (UKB){'\n'}
                        &copy; 2024. Alle Rechte vorbehalten.
                    </Text>

                    <View style={{flexDirection: 'row'}}>
                        <TouchableOpacity onPress={() => {
                            window.open(
                                'https://www.ukbonn.de/datenschutzerklaerung/'
                            )
                        }}>
                            <Text>Datenschutzerklärung</Text>
                        </TouchableOpacity>
                        <Text> | </Text>
                        <TouchableOpacity onPress={() => {
                            window.open(
                                'https://umfragetool.digital-medicine.org/terms'
                            )
                        }}>
                            <Text>Nutzungsbedingungen</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )}
        </View>
    );
}

LoginScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

// get width of screen to calculate camera view
const {width} = Dimensions.get('window');

const localStyle = StyleSheet.create({
    wrapper: {
        backgroundColor: theme.values.defaultBackgroundColor,
        flex: 1,
        width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
        alignSelf: 'center',
        position: 'static',
        bottom: 0,
    },

    content: {
        alignItems: 'center',
    },

    camera: {
        marginTop: appConfig.scaleUiFkt(20),
        maxHeight: 0.95 * width,
        width: '95%',
        aspectRatio: 1.0,
        justifyContent: 'center',
    },

    infoText: {
        marginStart: 10,
        marginEnd: 10,
        textAlign: 'center',
        color: theme.colors.accent4,
        ...theme.fonts.subHeader1,
    },

    button: {
        ...theme.classes.buttonAlert,
        bottom: 0,
        padding: appConfig.scaleUiFkt(15),
        width: '80%',
    },

    buttonLabel: {
        ...theme.classes.buttonLabel,
    },

    qrImage: {
        width: 100,
        height: 100,
    },

    access_button: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingHorizontal: 20,
        backgroundColor: theme.colors.primary_light,
        borderColor: theme.colors.secondary,
        borderWidth: 3,
        borderRadius: 10,
        padding: 10,
        marginBottom: 30,
        alignItems: 'center',
        alignSelf: 'flex-start',
        margin: 10,
    },

    streamView: {
        width: 400,
        height: 400,
    },

    stream: {
        flex: 1,
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

LoginScreen.screenName = 'Login';

export default LoginScreen;
