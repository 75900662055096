// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {Alert, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// components
import {ListItem} from '@rneui/themed';
import VersionCheck from '../components/shared/VersionCheck';

// custom components
import {AboutListItem, AboutListLink, RedirectModal} from '../components/about';
import {Banner, ScrollIndicatorWrapper, Spinner} from '../components/shared';

// redux actions
import {reset} from '../store/sharedActions';
import {setNotificationPreference, updateLanguage} from '../store/user.slice';

// services & config
import {appConfig, theme} from '../config';
import kioskMode from '../config/kioskApiConfig';
import translate from '../services/localization';
import {Routes, Stacks} from '../navigation/constants';

import {Dialog} from 'react-native-simple-dialogs';
import {Button} from 'react-native-paper';
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component:
 * renders the about screen which contains information about the app as well as some links to
 * websites and the screen with legal information necessary in some regions
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 **********************************************************************************************/
function AboutScreen({navigation}) {
    const dispatch = useDispatch();

    // internal state for the redirect modal
    const [modalState, setModalState] = useState({
        hidden: true,
        modalLink: null,
    });

    // get data from global state
    const started = useSelector((state) => state.Questionnaire.started);
    const subjectId = useSelector((state) => state.User.subjectId);
    const loading = useSelector((state) => state.Globals.loading);

    useEffect(() => {
        // got lo landing screen when reset was successful
        if (!subjectId) {
            navigation.replace(Stacks.SIGNED_OUT, {screen: Platform.OS === 'native' ? Routes.LANDING : Routes.LOGIN});
        }
    });

    const [receiveNotifications, setReceiveNotifications] = useState(false);
    const {notificationPreference} = useSelector((state) => state.User);
    useEffect(() => {
        setReceiveNotifications(notificationPreference);
    }, [notificationPreference]);

    const {categories} = useSelector((state) => state.Questionnaire);

    // information needed for different dialogs to be shown to user (for web as alternative to Alert)
    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogButtons, setDialogButtons] = useState([]);

    /**
     * shows a confirmation-dialog. if confirmed, it deletes the local data, logs the user
     * out and navigates back to the landing-screen.
     */
    const clearAll = () => {
        if (Platform.OS === 'native') {
            Alert.alert(
                translate('generic').warning,
                translate('generic').eraseAllWarning,
                [
                    {
                        text: translate('generic').delete,
                        onPress: () => {
                            dispatch(reset());
                        },
                    },
                    {
                        text: translate('generic').abort,
                        style: 'cancel',
                    },
                ],
                {cancelable: false},
            );
        } else {
            setDialogTitle(translate('generic').warning);
            setDialogMessage(translate('generic').eraseAllWarning);
            setDialogButtons([
                {
                    text: translate('generic').delete,
                    onPress: () => {
                        dispatch(reset()).then(() => {
                            console.log("Logout worked");
                        });
                        setShowDialog(false);
                    },
                },
                {
                    text: translate('generic').abort,
                    style: 'cancel',
                    onPress: () => setShowDialog(false),
                },
            ]);
            setShowDialog(true);
        }
    };

    // handler for the 'change language' spinner/menu
    const changeLanguage = (languageTag) => {
        // in case some some questions of the current questionnaire have been answered,
        // warn the user that those answers will be lost when changing the language
        if (started) {
            Alert.alert(
                translate('generic').warning,
                translate('about').languageWarning +
                translate('about').languageWarningAddition,
                [
                    {
                        text: translate('generic').delete,
                        onPress: () => {
                            dispatch(updateLanguage({languageTag, subjectId}));
                        },
                    },
                    {
                        text: translate('generic').abort,
                        style: 'cancel',
                    },
                ],
                {cancelable: false},
            );
        } else {
            dispatch(updateLanguage({languageTag, subjectId}));
        }
    };

    let version =
        VersionCheck.getCurrentVersion() +
        ', Build ' +
        VersionCheck.getCurrentBuildNumber();

    return loading ? (
        <Spinner/>
    ) : (
        <View style={localStyle.wrapper}>
            {/* top banner */}
            <Banner
                nav={navigation}
                title={translate('about').title}
                subTitle={translate('about').subTitle}
                noMenu
            />

            {/* Dialog to replace Alert.alert for web */}
            <Dialog
                visible={showDialog}
                title={dialogTitle}
                onTouchOutside={() => setShowDialog(false)}
                dialogStyle={{width: Platform.OS === 'web' && isMobile ? '95%' : 800, alignSelf: 'center'}}
            >
                <View>
                    <Text style={{marginBottom: 20}}>{dialogMessage}</Text>
                    <View style={{flexDirection: 'row'}}>
                        {dialogButtons.map((button) => (
                            <Button
                                mode="contained"
                                onPress={() => {
                                    button.onPress ? button.onPress() : null;
                                }}
                                style={{marginRight: 5}}
                            >
                                {button.text}
                            </Button>
                        ))}
                    </View>
                </View>
            </Dialog>

            {/* the modal to be opened */}
            <RedirectModal
                showModal={!modalState.hidden}
                modalLink={modalState.modalLink}
                hideModal={() => {
                    setModalState({hidden: true, modalLink: null});
                }}
            />

            {/* ScrollView with content */}
            <View style={localStyle.wrapper}>
                <ScrollIndicatorWrapper>
                    <View style={localStyle.wrapper}>
                        {/* holds the list-items */}
                        <View style={[localStyle.wrapper, {alignItems: 'left'}]}>
                            {/*<ListItem*/}
                            {/*  containerStyle={localStyle.containerStyle}*/}
                            {/*  onPress={() => navigation.navigate(Routes.WALKTHROUGH)}*/}
                            {/*>*/}
                            {/*  /!* title & subtitle of the listItem - the strings a identified by the webView*!/*/}
                            {/*  <ListItem.Content>*/}
                            {/*    <ListItem.Title style={localStyle.title}>*/}
                            {/*      {translate('about').intro.title}*/}
                            {/*    </ListItem.Title>*/}
                            {/*    <ListItem.Subtitle style={localStyle.subTitle}>*/}
                            {/*      {translate('about').intro.subTitle}*/}
                            {/*    </ListItem.Subtitle>*/}
                            {/*  </ListItem.Content>*/}

                            {/*  /!* the icon on the right-hand-side *!/*/}
                            {/*  <ListItem.Chevron*/}
                            {/*    {...{*/}
                            {/*      type: translate('about').intro.iconType,*/}
                            {/*      name: translate('about').intro.iconTitle,*/}
                            {/*      color: theme.values.legalListLinkIconColor,*/}
                            {/*      reverse: true,*/}
                            {/*      size: 12,*/}
                            {/*    }}*/}
                            {/*  />*/}
                            {/*</ListItem>*/}

                            {Platform.OS === 'native' && (
                                <ListItem containerStyle={localStyle.containerStyle}>
                                    {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                                    <ListItem.Content>
                                        <TouchableOpacity
                                            onPress={() => {
                                                let preference = !receiveNotifications;
                                                dispatch(
                                                    setNotificationPreference({preference, categories}),
                                                );
                                            }}
                                        >
                                            <ListItem.Title style={localStyle.title}>
                                                {'Benachrichtigungen'}
                                            </ListItem.Title>
                                            <View style={{flexDirection: 'row'}}>
                                                <View
                                                    style={{
                                                        transform: [{scale: 1.4}],
                                                        marginTop: 35,
                                                        paddingRight: 10,
                                                        paddingLeft: 10,
                                                    }}
                                                >
                                                    <ListItem.CheckBox
                                                        checked={receiveNotifications}
                                                        onPress={() => {
                                                            let preference = !receiveNotifications;
                                                            dispatch(
                                                                setNotificationPreference({
                                                                    preference,
                                                                    categories,
                                                                }),
                                                            );
                                                        }}
                                                    />
                                                </View>
                                                <View style={{marginRight: 50}}>
                                                    <ListItem.Subtitle
                                                        style={[localStyle.subTitle, {paddingTop: 10}]}
                                                    >
                                                        {
                                                            'Benachrichtigungen von der App als Erinnerung zu bevorstehenden Ereignissen erhalten'
                                                        }
                                                    </ListItem.Subtitle>
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    </ListItem.Content>
                                </ListItem>
                            )}


                            {(Platform.OS === 'android' || Platform.OS === 'ios') && (
                                <ListItem
                                    containerStyle={localStyle.containerStyle}
                                    onPress={() => navigation.navigate(Routes.BLE)}
                                >
                                    {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                                    <ListItem.Content>
                                        <ListItem.Title style={localStyle.title}>
                                            {translate('about').bleDevices.title}
                                        </ListItem.Title>
                                        <ListItem.Subtitle style={localStyle.subTitle}>
                                            {translate('about').bleDevices.subTitle}
                                        </ListItem.Subtitle>
                                    </ListItem.Content>

                                    {/*/!* the icon on the right-hand-side *!/*/}
                                    {/*<ListItem.Chevron*/}
                                    {/*    {...{*/}
                                    {/*      type: translate('about').bleDevices.iconType,*/}
                                    {/*      name: translate('about').bleDevices.iconTitle,*/}
                                    {/*      color: theme.values.legalListLinkIconColor,*/}
                                    {/*      reverse: true,*/}
                                    {/*      size: 12,*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    {Platform.OS === 'native' && (
                                        <ListItem.Chevron
                                            type="material-community"
                                            size={24}
                                            raised
                                            containerStyle={{backgroundColor: theme.colors.white}}
                                            // get additional properties based on the state of the questionnaire
                                            iconProps={{
                                                name: 'bluetooth',
                                                color: theme.colors.success,
                                            }}
                                        />
                                    )}
                                </ListItem>)}

                            {/* links to the LegalInformationScreen */}
                            {appConfig.allowAccessToLegalInformationScreen && (
                                <ListItem
                                    containerStyle={localStyle.containerStyle}
                                    onPress={() => {
                                        // navigation.navigate(Routes.LEGAL_INFORMATION)
                                        window.open(
                                            'https://www.ukbonn.de/datenschutzerklaerung/'
                                        )
                                    }}
                                >
                                    {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                                    <ListItem.Content>
                                        <ListItem.Title style={localStyle.title}>
                                            {translate('about').legal.title}
                                        </ListItem.Title>

                                        <ListItem.Subtitle style={localStyle.subTitle}>
                                            {translate('about').legal.subTitle}
                                        </ListItem.Subtitle>
                                    </ListItem.Content>

                                    {/* the icon on the right-hand-side */}
                                    <ListItem.Chevron
                                        {...{
                                            type: translate('about').legal.iconType,
                                            name: translate('about').legal.iconTitle,
                                            color: theme.values.legalListLinkIconColor,
                                            reverse: true,
                                            size: 12,
                                        }}
                                    />
                                </ListItem>
                            )}

                            {/* iterates over all items in translate('wevViews') */}
                            <View>
                            {translate('webViews').map((webView) => (
                                // navigates to the webview screen
                                    <AboutListLink
                                        key={webView.title}
                                        navigation={navigation}
                                        webView={webView}
                                    />
                            ))}
                            </View>

                            {/* iterates over all items in translate('modalLinks') */}
                            {translate('modalLinks').map((modalLink) => (
                                // navigates to the webview screen
                                <AboutListItem
                                    showModal={() => setModalState({hidden: false, modalLink})}
                                    key={modalLink.title}
                                    modalLink={modalLink}
                                />
                            ))}

                            {(Platform.OS === 'android' || Platform.OS === 'ios') && (
                                <ListItem
                                    containerStyle={localStyle.containerStyle}
                                    onPress={() => navigation.navigate(Routes.TIME)}
                                >
                                    {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                                    <ListItem.Content>
                                        <ListItem.Title style={localStyle.title}>
                                            {'Version'}
                                        </ListItem.Title>
                                        <ListItem.Subtitle style={localStyle.subTitle}>
                                            {version}
                                        </ListItem.Subtitle>
                                    </ListItem.Content>
                                </ListItem>
                            )}
                        </View>

                        {/* optional buttons on the bottom of the screen - JUST FOR DEVELOPMENT*/}
                        <View style={localStyle.bottom}>
                            {/* language picker */}
                            {(Platform.OS === 'android' || Platform.OS === 'ios') && (
                                <View style={localStyle.languagePickerWrapper}>
                                    <Text style={localStyle.title}>
                                        {translate('about').languageSelection}
                                    </Text>

                                    {started && (
                                        <Text style={localStyle.warningSubTitle}>
                                            {translate('about').languageWarning}
                                        </Text>
                                    )}

                                    {/*<Picker*/}
                                    {/*  testID="languagePicker"*/}
                                    {/*  style={localStyle.picker}*/}
                                    {/*  mode="dropdown"*/}
                                    {/*  selectedValue={getLanguageTag()}*/}
                                    {/*  onValueChange={(itemValue) => {*/}
                                    {/*    if (getLanguageTag() !== itemValue) {*/}
                                    {/*      changeLanguage(itemValue);*/}
                                    {/*    }*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  {Object.keys(availableLanguages).map((key) => (*/}
                                    {/*    <Picker.Item*/}
                                    {/*      key={key}*/}
                                    {/*      label={availableLanguages[key].title}*/}
                                    {/*      value={key}*/}
                                    {/*    />*/}
                                    {/*  ))}*/}
                                    {/*</Picker>*/}
                                </View>)}

                            {/* delete-all-data button */}
                            {/*{(appConfig.showEraseAll || kioskMode.active) && (*/}
                            <TouchableOpacity
                                style={localStyle.buttonAlert}
                                onPress={clearAll}
                                accessibilityLabel={translate('about').delete}
                                accessibilityRole={translate('accessibility').types.button}
                                accessibilityHint={translate('accessibility').logoutHint}
                            >
                                <Text style={localStyle.buttonLabel}>
                                    {kioskMode.active
                                        ? translate('about').demoDelete
                                        : translate('about').delete}
                                </Text>
                            </TouchableOpacity>
                            {/*)}*/}

                            <Text style={{marginTop: 10, textAlign: 'center'}}>
                                App-Entwicklung: Institut für Digitale Medizin,
                                Universitätsklinikum Bonn (UKB){'\n'}
                                &copy; 2024. Alle Rechte vorbehalten.
                            </Text>
                        </View>
                    </View>
                </ScrollIndicatorWrapper>
            </View>
        </View>
    );
}

AboutScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 local styling
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
        alignSelf: 'center',
        height: '100%',
        backgroundColor: theme.colors.white,
    },

    bottom: {
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: 36,
        height: '100%',
        marginTop: 5,
        width: '100%',
        padding: 15,
    },

    button: {
        ...theme.classes.buttonPrimary,
        bottom: 0,
        marginTop: 10,
    },

    buttonAlert: {
        ...theme.classes.buttonAlert,
        bottom: 0,
        marginTop: 20,
    },

    buttonLabel: {
        ...theme.classes.buttonLabel,
    },

    containerStyle: {
        width: '100%',
        borderBottomColor: theme.colors.accent3,
        borderBottomWidth: 1,
        backgroundColor: theme.values.defaultListLinkBackgroundColor,
        padding: 15,
    },

    subTitle: {
        color: theme.colors.accent4,
        ...theme.fonts.body,
    },

    warningSubTitle: {
        color: theme.colors.alert,
        ...theme.fonts.body,
    },

    title: {
        ...theme.fonts.title2,
    },

    titleText: {
        width: '80%',
        textAlign: 'center',
        alignSelf: 'center',
        ...theme.fonts.header2,
    },

    languagePickerWrapper: {
        borderWidth: 3,
        borderColor: theme.colors.white,
        borderRadius: 4,
        padding: 10,
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default AboutScreen;
