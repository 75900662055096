import React from 'react';
import {Platform, Text, useWindowDimensions, View} from 'react-native';
import RenderHtml, {HTMLContentModel, HTMLElementModel} from 'react-native-render-html';

import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {itemPropType} from '../../propTypes';

// services & config
import questionnaireAnalyzer from '../../services/questionnaireAnalyzer';

// custom input components for the different types of questions
import BasicInput from './input/basicInput';
import BooleanInput from './input/booleanInput';
import ChoicesInput from './input/choicesInput';
import SegmentedButtonsInput from './input/segmentedButtonsInput';
import DateInput from './input/dateInput';
import SliderInput from './input/sliderInput';

// shared styles & style calculations
import SharedStyles, {calculateFontSize, calculateIndent, calculateLineHeight,} from './input/sharedStyles';
import BasicMultiInput from "../../components/questionnaireModal/input/basicMultiInput";
import {stripTags} from "../../services/utils";
import ModalInfo from "../../components/questionnaireModal/modalInfo";
import DeviceInfo from "react-native-device-info";

const renderers = {};

/***********************************************************************************************
 * component:
 * renders a single FHIR-Questionnaire Item
 * should this item be oif type group, its child items are rendered recursively
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
export default function QuestionnaireItem({item, handleForwardPress}) {

    const questionnaireItemMap = useSelector(
        (state) => state.Questionnaire.itemMap,
    );

    let linkIds = Object.keys(questionnaireItemMap);


    // if the item represents a group of questions, display the title of the group and render the children below
    if (item.type === 'display') {
        const {width} = useWindowDimensions();
        let text = item.text;

        const customHTMLElementModels = {};

        const fieldEmbeddingRegex = /\{(.+?)\}/g;
        let matches = item.text.matchAll(fieldEmbeddingRegex);

        if (matches) {

            for (let captureGroups of matches) {
                text = text.replace(captureGroups[0], '<' + captureGroups[1] + '>' + captureGroups[1] + '</' + captureGroups[1] + '>')

                customHTMLElementModels[captureGroups[1]] = HTMLElementModel.fromCustomModel({
                    tagName: captureGroups[1],
                    mixedUAStyles: {
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        alignSelf: 'center',
                        backgroundColor: 'blue'
                    },
                    contentModel: HTMLContentModel.block
                })

                let embeddedFieldName = captureGroups[1];
                console.log('found embedded', embeddedFieldName);

                for (let linkId of linkIds) {
                    let origCode = questionnaireItemMap[linkId]?.origCode;
                    if (!!origCode && origCode === embeddedFieldName) {

                        if (!renderers[origCode]) {
                            function Renderer({
                                                  TDefaultRenderer,
                                                  tnode,
                                                  ...defaultRendererProps
                                              }) {
                                return (<QuestionnaireItem
                                    handleForwardPress={handleForwardPress}
                                    item={questionnaireItemMap[linkId]}
                                    key={linkId}
                                />);
                            }

                            renderers[origCode] = Renderer;
                        }
                    }
                }

            }

            // TODO Conditions to move on resolved?
        }


        const pipingRegex = /\[(.+?)\]/g;
        matches = text.matchAll(pipingRegex);

        if (matches) {
            for (let captureGroups of matches) {

                for (let linkId of linkIds) {
                    let origCode = questionnaireItemMap[linkId]?.origCode;
                    if (!!origCode && origCode === captureGroups[1]) {
                        let conditions = questionnaireAnalyzer.checkConditionsOfSingleItem(
                            questionnaireItemMap[linkId],
                            questionnaireItemMap,
                        )
                        if (!conditions) {
                            text = text.replace(captureGroups[0], '');
                        } else {
                            if (questionnaireItemMap[linkId].type == 'choice') {
                                let textAnswers = '';
                                questionnaireItemMap[linkId]?.answer?.forEach((answer) => {
                                    textAnswers += answer?.valueCoding?.display + '<br />';
                                })
                                text = text.replace(captureGroups[0], textAnswers);
                            }
                        }
                        break;
                    }
                }
            }
        }


        const source = {
            html: '<div style="font-weight: bold; font-size: ' + (DeviceInfo.isTablet()? '1.75em' : '1.2em') + '">' + text + '</div>'
        };

        if (
            !questionnaireAnalyzer.checkConditionsOfSingleItem(
                item,
                questionnaireItemMap,
            )
        ) {
            return (<RenderHtml
                contentWidth={width}
                source={{
                    html: '<span></span>'
                }}
            />);
        }

        return (<>
            {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) &&
                <ModalInfo
                    infoText={stripTags(item.fieldAnnotation)}
                />
            }
            <RenderHtml
                contentWidth={width}
                source={source}
                renderers={renderers}
                customHTMLElementModels={customHTMLElementModels}
                enableExperimentalBRCollapsing={true}
            /></>
        );

    } else {
        if (
            !questionnaireAnalyzer.checkConditionsOfSingleItem(
                item,
                questionnaireItemMap,
            )
        ) {
            return (<></>);
        }

        let itemControlExtension;
        let isSlider;
        let questionItem;
        switch (item.type) {
            // creates regular inputs for strings
            case 'integer':
            case 'decimal':
            case 'string':
            case 'email':
                if (
                    item.fieldAnnotation &&
                    item.fieldAnnotation.includes('[slider|') &&
                    item.fieldAnnotation.indexOf(']') != -1
                ) {
                    questionItem = <SliderInput item={item} key={item.linkId}/>;
                } else if (
                    item.fieldAnnotation &&
                    item.fieldAnnotation.includes('[multiinput')
                ) {
                    questionItem = <BasicMultiInput item={item} key={item.linkId}/>;
                } else {
                    questionItem =
                        <BasicInput handleForwardPress={handleForwardPress} item={item} key={item.linkId}/>;
                }
                break;
            // creates either a list of radio buttons, a list of checkboxes or a drop-down element
            case 'choice':
                if (item.fieldAnnotation.includes('[matrix')) {
                    questionItem = <SegmentedButtonsInput item={item} key={item.linkId}/>;
                } else if (item.fieldAnnotation.includes('[slider')) {
                    questionItem = <SliderInput item={item} key={item.linkId}/>;
                } else {
                    questionItem = <ChoicesInput item={item} key={item.linkId}/>;
                }
                break;
            // creates a checkbox
            case 'boolean':
                return <BooleanInput item={item} key={item.linkId}/>;

            // creates a date input
            case 'date':
            case 'time':
            case 'datetime':
                questionItem = <DateInput item={item} key={item.linkId}/>;
                break;
            // creates the inputs for decimals and integers (and numerical sliders)
            // this also utilizes the decimal-pad or the num-pad
            case 'slider':
                questionItem = <SliderInput item={item} key={item.linkId}/>;
                break;
            // if nothing else matches - display the title
            default:
                questionItem = (
                    <Text
                        style={{
                            ...SharedStyles.contentTitle,
                            fontSize: calculateFontSize(item.linkId),
                            lineHeight: calculateLineHeight(item.linkId),
                            marginLeft: calculateIndent(item.linkId),
                        }}
                    >
                        {item.text}
                    </Text>
                );
        }
        return (
            <>
                {/* the question item itself */}
                {questionItem}
                {/* nested items of the question if existent */}
                {item.item &&
                    item.item.map((subItem) => (
                        <QuestionnaireItem item={subItem} key={subItem.linkId}/>
                    ))}
            </>
        );
    }
}

QuestionnaireItem.propTypes = {
    item: PropTypes.shape(itemPropType).isRequired,
};
