import * as React from 'react';
import {Appbar, Button, Text} from 'react-native-paper';
import {Routes} from "../navigation/constants";
import {Modal, StyleSheet, View} from "react-native";
import RoundedButton from "./RoundedButton";
// import {dismiss} from "react-native/Libraries/LogBox/Data/LogBoxData";

const ModalDialog = ({ navigation, confirmEntryDialogVisible, setConfirmEntryDialogVisible, status, mm, instant, x,y,z }) => (
    <Modal visible={confirmEntryDialogVisible} onDismiss={() => {setConfirmEntryDialogVisible(false)}}
           >
        <View style={styles.modal}>
            <View style={{flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30}}>


                <Text style={{ fontSize: 20 }}>
                    status: {status}&nbsp;
                </Text>

                <Text style={{ fontSize: 20 }}>
                    mm: {mm}&nbsp;
                </Text>
                <Text style={{ fontSize: 20 }}>
                    instant: {instant}&nbsp;
                </Text>


                <Text style={{ fontSize: 20 }}>
                    x: {x}&nbsp;
                </Text>
                <Text style={{ fontSize: 20 }}>
                    y: {y}&nbsp;
                </Text>
                <Text style={{ fontSize: 20 }}>
                    z: {z}&nbsp;
                </Text>
            </View>
            <View style={{flexDirection: 'column', flexWrap: 'wrap', marginBottom: 30, alignItems: 'flex-end'}}>
                <RoundedButton onPress={() => {setConfirmEntryDialogVisible(false)}} text={'Abbrechen'} />

            </View>
        </View>
    </Modal>
);

const styles = StyleSheet.create({
    modal: {
        padding: 20,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
    }
});

export default ModalDialog;
