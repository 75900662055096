/**
 * utility functions used throughout the application
 */

import moment from 'moment';
import 'moment/locale/de';
import PushNotification from "../components/shared/PushNotification";
import VersionCheck from "../components/shared/VersionCheck";
import endpoints from "./rest/endpoints";
import translate from "./localization";
import {Alert, Linking, Platform} from "react-native";
import i18n from "i18n-js";
import {Routes, Stacks} from "../navigation/constants";
import {setNewVersionURL} from "../store/globals.slice";
import notifee from "../components/shared/notifee"; 
import AndroidNotificationSetting from "../components/shared/androidNotificationSetting";

/**
 * generates a date string from Date Object (dd.mm.yyyy)
 * @param  {Date}    inputDate the input date
 * @param  {boolean} includeTime if true: the exported string will also contain the time
 */
const formatDateString = (inputDate, { includeTime, locale = 'de' }) => {
  return moment(new Date(inputDate)).locale(locale).format(!includeTime ? 'LL' : 'LLL');
};

/**
 * converts a yyyy-mm-dd string to a Date object
 * @param  {Date}    inputDate the input date string in yyyy-mm-dd format
 */
const yyyymmddToDate = (dateString) => {
  const dateParts = dateString.split('-').map(Number);

  // Ensure there are three parts (year, month, day)
  if (dateParts.length !== 3) {
    return null;
  }

  const [year, month, day] = dateParts;

  // Check if the date is valid
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return null;
  }

  const dateObject = new Date(year, month - 1, day);

  // Check if the date is valid after considering month indexing (0-based in JavaScript)
  if (
      dateObject.getFullYear() !== year ||
      dateObject.getMonth() !== month - 1 ||
      dateObject.getDate() !== day
  ) {
    return null;
  }

  return dateObject;
}

const setScheduledNotifications = (categories, updateQuestionnaireScheduleTimes = false) => {
    if (Platform.OS === 'native') {
  
    PushNotification.cancelAllLocalNotifications();

    categories?.map((category, categoryIndex) => {

        if (!category.item[0]?.fieldAnnotation) {
            return;
        }
        let scheduleTimes = extractScheduleStartEndTimes(category.item[0].fieldAnnotation);
        if (updateQuestionnaireScheduleTimes) {
            category.scheduleTimes = []; // FIXME
        }

        if (!scheduleTimes) {
            return;
        }

        for (let s = 0; s < scheduleTimes.length; s++) {
            /* let notificationDates = */
            scheduleNotificationBetweenTimes(
                10000 * categoryIndex + s,
                "Ein Fragebogen steht bereit",
                "Bitte füllen Sie den Fragebogen \"" + category.text + " \" jetzt aus.",
                scheduleTimes[s].start,
                scheduleTimes[s].end,
                scheduleTimes[s].repeat);

            if (updateQuestionnaireScheduleTimes) {
                category.scheduleTimes.push({...scheduleTimes[s] /*, notificationDate: notificationDates */});
            }
            // TODO: What when the last day expires, and the notifications are not generated anymore?
        }

        console.log('category.scheduleTimes')
        console.log(category.scheduleTimes);

    });
  }
}


function getNextEarliestRandomDateBetween(start, end, startDate) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

    let startTime, endTime;
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);
    if (startDate && startDate >= today) {
        // If the startDate is in the future, use the start and end times of that date
        startTime = new Date(startDate);
        startTime.setHours(startHour, startMinute, 0, 0);
        endTime = new Date(startDate);
        endTime.setHours(endHour, endMinute, 0, 0);
    } else {
        startTime = new Date(today.getTime() + startHour * 60 * 60 * 1000 + startMinute * 60 * 1000);
        endTime = new Date(today.getTime() + endHour * 60 * 60 * 1000 + endMinute * 60 * 1000);
        if (now >= endTime) {
            // If the current time is past the end time, calculate for the next day
            startTime.setDate(startTime.getDate() + 1);
            endTime.setDate(endTime.getDate() + 1);
        }
    }

    const randomTime = startTime.getTime() + Math.random() * (endTime.getTime() - startTime.getTime());
    return new Date(randomTime);
}

// TODO Set global array of currently due ones / on notification
let scheduleNotificationBetweenTimes = (notificationId, title, body, startTime, endTime, repeat, stopAfter) => {
    let end = 1;
    let increment = 0;

    if (repeat == 'daily') {
        end = 30; // FIXME Max number of concurrent alarms is 500, this is a very bad solution
        increment = 3600 * 24;
        console.log('daily ');
    }
    console.log('between times');

    let notificationDates = [];
    for (let d = 0; d < end; d++) {
        let date = new Date();
        date.setSeconds(date.getSeconds() + d * increment);
        let scheduledDate = getNextEarliestRandomDateBetween(startTime, endTime, date);
        if (scheduledDate < new Date()) {
            continue;
        }
        console.log(scheduledDate);
        notificationDates.push(scheduledDate);

        const notificationOptions = {
            channelId: "umfragetool",
            notificationId: notificationId + "",
            title: "Umfragetool UK Bonn: " + title,
            message: body,
            date: scheduledDate,
            playSound: true,
            allowWhileIdle: true,

            largeIcon: 'push_notification_icon',
            smallIcon: 'push_notification_icon',
            bigLargeIcon: 'push_notification_icon'
        };
        notifee.getNotificationSettings().then((settings) => {
            if (settings.android.alarm === AndroidNotificationSetting.ENABLED) {
                PushNotification.localNotificationSchedule(notificationOptions);
            }
        });

    }
    return notificationDates;
}


const extractScheduleStartEndTimes = (inputString) => {
    const regex = /\[notify\|(daily)*\|(\d{2}:\d{2})\|(\d{2}:\d{2})(\|(\d)){0,1}\]/g;
    const matches = [];
    let match;


    while ((match = regex.exec(inputString)) !== null) {
        let params = {
            start: match[2],
            end: match[3],
            repeat: match[1]
        }
        if (!!match[5]) {
            params.stopAfter = parseInt(match[5]);
        }
        matches.push(params);
    }

    return matches;
}

const stripTags = (input: string) => {
  return input.replace(/(\[.*?\])/gm, '').trim();
}

const versionCheck = (navigation, dispatch, currentRoute, subjectId) => {
    let country = '';
    VersionCheck.getCountry()
        .then(c => country = c);
    const body = {
        'country': country,
        'lang': i18n.locale,
        'package': VersionCheck.getPackageName(),
        'build': VersionCheck.getCurrentBuildNumber(),
        'version': VersionCheck.getCurrentVersion()
    };

    // let endpoint = 'https://checkversionapi1.free.beeceptor.com';
    let endpoint = endpoints.checkVersion;
    console.log('versioncheck');
    VersionCheck.getLatestVersion({
        forceUpdate: true,
        provider: () => fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(r => r.json(), function (error) {
                console.log("ERROR: ", error.message)
            })
            .then(a => {
                console.log(a);
                return a
            }),
    }).then(updateResponse => {
        const infoURL = updateResponse.infoURL;

        if (updateResponse.inMaintenanceMode && currentRoute?.name !== Routes.MAINTENANCE_MODE) { // check if should get into maintenance mode
            navigation.replace(Stacks.FORCE_NEW_VERSION, {screen: Routes.MAINTENANCE_MODE});
            return;
        } else if (!updateResponse.inMaintenanceMode && currentRoute?.name === Routes.MAINTENANCE_MODE) {
            navigation.replace(subjectId ? Stacks.SIGNED_IN : Stacks.SIGNED_OUT);
            return;
        }

        if (updateResponse.forceUpdate) {
            dispatch(setNewVersionURL(infoURL))
            navigation.replace(Stacks.FORCE_NEW_VERSION, {screen: Routes.LOAD_NEW_VERSION});
        } else if (updateResponse.updateAvailable) {
            let buttons = [];

            buttons.push(
                {
                    text: translate('generic').abort,
                    style: 'cancel',
                });

            console.log("infoURL", infoURL);
            buttons.push(
                {
                    text: translate('generic').ok,
                    onPress: () => {
                        console.log(updateResponse);
                        if (infoURL) {
                            Linking.openURL(infoURL);
                        }
                        if (updateResponse.forceUpdate) {
                            navigation.replace(Stacks.FORCE_NEW_VERSION, Routes.LOAD_NEW_VERSION);
                        }
                    },
                }
            );

            Alert.alert(
                translate('generic').info,
                updateResponse.infoText ?? "Eine neue Version der App ist verfügbar.",
                buttons,
                {cancelable: false},
            );
        }
    }).catch((e) => {
        console.error('Error while checking version')
        console.error(e)
    });
}

const markdownToHtml = (markdown: string) => {

    // Ignore within HTML
    const htmlTagRegex = /<("[^"]*"|'[^']*'|[^'">])*>/g;
    if (markdown.match(htmlTagRegex)) {
        return markdown;
    }

    const breakRegex = /\r?\n/g;
    markdown = markdown.replace(breakRegex, '<br />');

    // Replace boldface syntax (**) with <strong> tags
    const boldRegex = /\*{2}(.+?)\*{2}/g;
    markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

    // Replace italic syntax (//) with <em> tags
    const italicRegex = /\/\/(.+?)\/\//g;
    markdown = markdown.replace(italicRegex, "<em>$1</em>");

    // Replace underlined syntax (__) with <u> tags
    const underlineRegex = /_{2}(.+?)_{2}/g;
    markdown = markdown.replace(underlineRegex, "<u>$1</u>");

    return markdown;
}

// eslint-disable-next-line import/prefer-default-export
export {formatDateString};
export {stripTags};
export {yyyymmddToDate};
export {setScheduledNotifications};
export {extractScheduleStartEndTimes};
export {versionCheck};
export {markdownToHtml};
