// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React, { useState, useEffect } from 'react';
import { Alert, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// components

// custom components
import { Banner } from '../components/shared';

// redux actions

// services & config
import { appConfig, theme } from '../config';
import translate from '../services/localization';
import moment from 'moment';

/***********************************************************************************************
 * component:
 * renders the about screen which contains information about the app as well as some links to
 * websites and the screen with legal information necessary in some regions
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 **********************************************************************************************/
function TimeScreen({ navigation }) {
  const dispatch = useDispatch();

  // internal state for the redirect modal
  const [time, setTime] = useState(moment().format("DD.MM.YYYY[\n]HH:mm:ss.SSS"));

  let interval;

  useEffect(() => {

    interval = setInterval(() => {
      setTime(moment().format("DD.MM.YYYY[\n]HH:mm:ss.SSS"));
    }, 5);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <View style={localStyle.wrapper}>
      {/* top banner */}
      <Banner
        nav={navigation}
        title={translate('about').title}
        subTitle={translate('about').subTitle}
        noMenu
      />

      <Text>Aktuelle Uhrzeit auf dem Gerät</Text>
      <Text>(zur Synchronisation bei externer Kameraufnahme)</Text>
      <View>
        <Text style={localStyle.titleText}>{time}</Text>
      </View>
    </View>
  );
}

TimeScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
local styling
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.accent0,
  },

  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 36,
    height: '100%',
    marginTop: 5,
    width: '100%',
    padding: 15,
  },

  button: {
    ...theme.classes.buttonPrimary,
    bottom: 0,
    marginTop: 10,
  },

  buttonAlert: {
    ...theme.classes.buttonAlert,
    bottom: 0,
    marginTop: 20,
  },

  buttonLabel: {
    ...theme.classes.buttonLabel,
  },

  containerStyle: {
    width: '100%',
    borderBottomColor: theme.colors.accent3,
    borderBottomWidth: 1,
    backgroundColor: theme.values.defaultListLinkBackgroundColor,
    padding: 15,
  },

  subTitle: {
    color: theme.colors.accent4,
    ...theme.fonts.body,
  },

  warningSubTitle: {
    color: theme.colors.alert,
    ...theme.fonts.body,
  },

  title: {
    ...theme.fonts.title2,
  },

  titleText: {
    width: '100%',
    marginTop: '50%',
    fontFamily: 'IBMPlexSans-SemiBold',
    fontSize: appConfig.scaleFontsFkt(55),
    lineHeight: appConfig.scaleFontsFkt(55 + 5),
    transform: [{ rotate: '90deg' }]
  },

  languagePickerWrapper: {
    borderWidth: 3,
    borderColor: theme.colors.white,
    borderRadius: 4,
    padding: 10,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default TimeScreen;
