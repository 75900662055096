import React, {useState, useEffect} from 'react';
import {I18nManager, View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, Platform} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {itemPropType} from '../../../propTypes';

import debounce from 'lodash.debounce';

// components
import {Input} from '@rneui/themed';

// redux actions
import {setAnswer} from '../../../store/questionnaire.slice';

// services & config
import {theme} from "../../../config";
import translate from '../../../services/localization';

// shared styles
import SharedStyles from './sharedStyles';
import RenderHtml from "react-native-render-html";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";
import {markdownToHtml, stripTags} from "../../../services/utils";
import {Button, IconButton, TextInput} from "react-native-paper";
import DeviceInfo from "react-native-device-info";

/**
 * is used to determine what kind of keyboard should be used
 * @param  {QuestionnaireItem} item a questionnaire item (from props.categories)
 */
const getKeyboardType = (item) => {
    switch (item.type) {
        // numpad for integers
        case 'integer':
            return 'number-pad';
        // decimalPad for decimals
        case 'decimal':
            return 'decimal-pad';
        // and the rest
        default:
            return 'default';
    }
};

/**
 * debounce the update of the global state for slightly better performance;
 * instead of dispatching an action after each keystroke, we wait for 350ms
 * should another keystroke occur before 350ms have passed, the previous actions is interrupted
 * and a new action with the updated parameters is enqueued
 *
 */
const setGlobalAnswer = debounce((item, retVal, dispatch) => {
    dispatch(setAnswer({answer: retVal, linkId: item.linkId}));
}, 350);

/***********************************************************************************************
 * renders a questionnaire item as basic input element for either strings, decimals, or integers
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
export default function BasicMultiInput({item, handleForwardPress}) {
    const dispatch = useDispatch();

    // get currentValue from state
    const globalValue = useSelector(
        (state) =>
            // whatever the item type, retrieve the current value if existent
            Object.values(
                state.Questionnaire.itemMap[item.linkId].answer?.[0] ?? {},
            )[0],
    );

    // internally store value of input
    const [localValue, setLocalValue] = useState(null);

    const [items, setItems] = useState(globalValue ? globalValue.split("../../../ ") : [""]);

    // error message in case input is not valid
    const [errorMsg, setErrorMsg] = useState('');

    // when the component is updated get current value from global state if local value does not exist
    useEffect(
        () => setLocalValue(localValue ?? globalValue),
        [localValue, globalValue],
    );

    const addItem = () => {
        setItems([...items, ""])
    }
    const removeItem = (idx) => {
        let newItems = [...items];
        newItems.splice(idx, 1);
        setItems(newItems);
        updateGlobalAnswer(newItems);
    }

    // check and validate input
    const handleInputChange = (input, idx) => {
        let newItems = [...items];
        newItems[idx] = input;
        setItems(newItems);
        updateGlobalAnswer(newItems);
    };

    const updateGlobalAnswer = (newItems) => {
        let answerString = newItems.join('\n../../../ ');
        console.log('this is the answer')
        console.log(answerString
            ? {
                [`value${item.type.charAt(0).toUpperCase() + item.type.slice(1)}`]:
                answerString,
            }
            : null)

        setGlobalAnswer(
            item,
            answerString
                ? {
                    [`value${item.type.charAt(0).toUpperCase() + item.type.slice(1)}`]:
                    answerString,
                }
                : null,
            dispatch,
        );
    }

    const {width} = useWindowDimensions();
    const source = {
        html: '<div style="font-weight: bold; font-size: ' + (DeviceInfo.isTablet()? '1.75em' : '1.2em') + '">' + markdownToHtml(item.text) + '</div>'
    };

    return (
        <View style={SharedStyles.modalInput}>
            {/* title */}
            {Platform.OS === 'web' ? (<div
                dangerouslySetInnerHTML={{__html: source.html}}
            />) :
                (<RenderHtml
                    contentWidth={width}
                    source={source}
                />)
            }

            {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) &&
                <ModalInfo
                    infoText={stripTags(item.fieldAnnotation)}
                />
            }
            {/* input */}
            {
                items.map((i, idx) => (
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'center',
                        width: '85%',
                        marginTop: 15
                    }}>
                        <Text>{idx+1}. </Text>
                        <TextInput
                            containerStyle={SharedStyles.modalContainer}
                            placeholder={translate('login').inputPlaceholder}
                            value={i}
                            keyboardType={getKeyboardType(item)}
                            style={{
                                textAlign: (item.type == 'integer' || item.type == 'decimal') ? 'right' : 'left',
                                backgroundColor: theme.colors.primary_light,
                                width: '85%'
                            }}
                            maxLength={item.maxLength || null}
                            // accessibilityLabel={ }
                            accessibilityHint={
                                translate('accessibility').questionnaire.textFieldHint
                            }
                            onChangeText={(text) => {
                                handleInputChange(text, idx);
                            }}
                        />
                        <IconButton
                            icon="close"
                            iconColor={'white'}
                            size={13}
                            onPress={() => {
                                removeItem(idx);
                            }}
                            containerColor={theme.colors.no}
                            mode={"contained"}
                        />
                    </View>
                ))
            }
            {

                <TouchableOpacity
                    style={{flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center', marginTop: 15}}
                    onPress={addItem}>
                    <IconButton
                        icon="plus"
                        iconColor={'white'}
                        size={13}
                        containerColor={theme.colors.yes}
                        mode={"contained"}
                    />
                    <Text>Neues Item hinzufügen</Text>
                </TouchableOpacity>
            }
        </View>
    );
}

BasicMultiInput.propTypes = {
    item: PropTypes.shape(itemPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    alignment: {
        width: 200,
        textAlign: I18nManager.isRTL ? 'right' : 'left',
    },
});
