// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {PermissionsAndroid, Platform, StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// components
import { ListItem } from '@rneui/themed';
// TODO: find alternative for web? Or reactivate in config.override file
// import { BleManager, State } from 'react-native-ble-plx';
import base64 from 'react-native-base64';
import RNFS from '../components/shared/RNFS';
// TODO: check how to use this in web and if it is necessary
// import {zip} from 'react-native-zip-archive'

// custom components
import {RedirectModal} from '../components/about';
import {Banner, ScrollIndicatorWrapper, Spinner} from '../components/shared';

// redux actions
import {setBLEBatteryLevel, setBLEBufferSize, setBLEDevice, setBLEDeviceState, setBLErssi} from '../store/globals.slice';
import {setLastBLEDataReceived, addToEventBuffer} from '../store/user.slice';

// services & config
import {appConfig, theme} from '../config';
import translate from '../services/localization';
import ModalDialog from "../components/ModalDialog";
import InfoDialog from "../components/InfoDialog";
import BackgroundService from 'react-native-background-actions';
import {loggedInClient} from "../services/rest";
import {check, PERMISSIONS, request, RESULTS} from "react-native-permissions";
import PushNotification from '../components/shared/PushNotification';


let disconnectRequest = false;
let scanStarted = false;
let device = null;
let deviceState = null;
let deviceBattery = null;
let deviceRSSI = null;
let isReconnecting = false;
let lastStreamingTimestamp = null;
let backgroundServiceStarted = false;
let repeatingNotifications = {};

const REDCAP_FIELD = 'data_moodmetric';
const MAX_BUFFER_SIZE = 3 * 3600 * 2; // 3 Hz, every four hours, times 2 as there are 2 characteristics
let dataBuffer = [];

// RNFS.mkdir(appConfig.csvFilePath);
// RNFS.mkdir(appConfig.zipFilePath);

/***********************************************************************************************
 * component:
 * renders the about screen which contains information about the app as well as some links to
 * websites and the screen with legal information necessary in some regions
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 **********************************************************************************************/
function BLEManagerScreen({navigation}) {

    const NOTIFICATION_ID_RECEIVING_DATA_AGAIN = '4444';
    const NOTIFICATION_ID_NODATA_RECEIVED = '5555';
    const NOTIFICATION_ID_BATTERY_LOW = '6666';
    const NOTIFICATION_ID_DISCONNECTED = '7777';

    const batteryServiceUUID = '0000180F-0000-1000-8000-00805F9B34FB';
    const batteryCharacteristicUUID = '00002A19-0000-1000-8000-00805F9B34FB'

    const mmServiceUUID = "dd499b70-e4cd-4988-a923-a7aab7283f8e";
    const streamingCharacteristicUUID = 'a0956420-9bd2-11e4-bd06-0800200c9a66';
    const rawDataCharacteristicUUID = 'af1b41cde-dbf5-4acf-8679-ecb8b4dca6ff';

    const dispatch = useDispatch();

    const [permissionsGranted, setPermissionsGranted] = useState(false);
    const [bleDevices, setBleDevices] = useState([]);
    const [deviceBatteryLevel, setDeviceBatteryLevel] = useState(null);
    const [bleAdapterState, setBleAdapterState] = useState(State.Unknown);


    const [modalTitle, setModalTitle] = useState("");
    const [modalText, setModalText] = useState("");
    const [dialogVisible, setDialogVisible] = useState(false);


    // get data from global state
    const subjectId = useSelector((state) => state.User.subjectId);
    const loading = useSelector((state) => state.Globals.loading);
    const last_ble_data_received = useSelector((state) => state.User.last_ble_data_received);

    const {BLEdevice, BLEbufferSize, BLEBatteryLevel} = useSelector((state) => state.Globals);

    const [lastDataReceived, setLastDataReceived] = useState(last_ble_data_received);
    const [bufferSize, setBufferSize] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        console.log('initial buffer size', BLEbufferSize);
        if (!BLEbufferSize) {
            RNFS.readDir(appConfig.zipFilePath).then((dirItems) => {
                if (dirItems.length > 0) {
                    setBufferSize(dirItems.length);
                }
            });
        }
        setBufferSize(BLEbufferSize);
        return () => {
            setBufferSize(0);
        };
    }, [BLEbufferSize]);

    useEffect(() => {
        setDeviceBatteryLevel(BLEBatteryLevel);
        return () => {
            setDeviceBatteryLevel(0);
        };
    }, [BLEBatteryLevel]);

    useEffect(() => {
        setLastDataReceived(last_ble_data_received);
        return () => {
            setLastDataReceived('');
        };
    }, [last_ble_data_received]);

    function displayNotification(title, body, options) {
        const notificationOptions = {
            channelId: "umfragetool", // (required) channelId, if the channel doesn't exist, notification will not trigger.

            /* iOS and Android properties */
            title: "Umfragetool UK Bonn: " + title, // (optional)
            message: body,
            playSound: true, // (required),
            allowWhileIdle: true,

            largeIcon: 'push_notification_icon',
            smallIcon: 'push_notification_icon',
            bigLargeIcon: 'push_notification_icon'
        };
        if (options?.notificationId) {
            notificationOptions['id'] = options.notificationId + "";
        }
        if (options?.channelId) {
            notificationOptions['channelId'] = options.channelId;
        }

        if (options?.timeout) {
            notificationOptions['timeoutAfter'] = options.timeout;
        }
        if (options?.soundName) {
            notificationOptions['soundName'] = options.soundName;
        }
        if (options?.delaySeconds) {
            notificationOptions['delaySeconds'] = options['delaySeconds'];
        }
        if (options?.repeatInterval && options?.notificationId) {
            // we are checking here if we are already having the id in our repeating notification queue
            if (!(options.notificationId in repeatingNotifications)) {
                _displayNotification(notificationOptions);
                repeatingNotifications[options.notificationId] = setInterval(() => {
                    _displayNotification(notificationOptions);
                }, options.repeatInterval);
            }
        } else {
            _displayNotification(notificationOptions);
        }
    }

    function _displayNotification(options) {
        if (options?.delaySeconds) {
            let date = new Date();
            date.setSeconds(date.getSeconds() + options.delaySeconds);
            options['date'] = date;
            console.log('delaySeconds')
            console.log(date)
            PushNotification.localNotificationSchedule(options);
        } else {
            PushNotification.localNotification(options);
        }
    }

    function cancelNotification(notificationId) {
        PushNotification.cancelLocalNotification(notificationId);
        if (notificationId in repeatingNotifications) {
            clearInterval(repeatingNotifications[notificationId]);
            delete repeatingNotifications[notificationId];
        }
    }

    const readBatteryLevel = async (device) => {
        console.log('reading battery level...')
        const batteryCharacteristic = await device.readCharacteristicForService(
            batteryServiceUUID,
            batteryCharacteristicUUID
        );
        const batteryResult = await batteryCharacteristic.read();

        const batteryValue = batteryResult.value;
        // console.log('This is the battery characteristic ' + batteryValue);
        const binaryString = base64.decode(batteryValue)
        // console.log('This is the battery characteristic ' + binaryString);
        const bytes = new Uint8Array(binaryString.length)
        // console.log('This is the battery characteristic ' + bytes);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const bytesString = (await bytes).toString();
        dispatch(setBLEBatteryLevel(bytesString));
        deviceBattery = bytesString;
        // console.log(bytesString + '%');

        if (bytesString <= appConfig.criticalBatteryLevel) {
            displayNotification('Akku fast leer', 'Der Akku des Moodmetric-Rings liegt bei ' + bytesString + '%. Bitte laden Sie ihn demnächst auf.',
                {
                    notificationId: NOTIFICATION_ID_BATTERY_LOW
                });
            dispatch(addToEventBuffer('BLE battery low ' + BLEdevice?.name + ' ' + BLEdevice?.id));
        }

        return bytesString;
    };

    const readRSSI = async (device) => {
        console.log('reading RSSI...');
        await appConfig.bleManager.readRSSIForDevice(
            device.id
        ).then((device) => {
            dispatch(setBLErssi(device.rssi));
            deviceRSSI = device.rssi;
        })
    };

    const addToBuffer = (buffer, data, filePath) => {
        buffer.push(data);

        if (buffer.length >= MAX_BUFFER_SIZE) { // submit buffer when reaching MAX_BUFFER_SIZE
            // deep copy first MAX_BUFFER_SIZE items
            let b = JSON.parse(JSON.stringify(buffer.slice(0, MAX_BUFFER_SIZE)));
            // remove first MAX_BUFFER_SIZE elements
            buffer = buffer.splice(0, MAX_BUFFER_SIZE)

            generateCSVzip(b, filePath)
                .then(() => {
                    uploadZipFiles(REDCAP_FIELD);
                });
        }

        dispatch(setLastBLEDataReceived(new Date().toLocaleString('de-DE', {timeZone: 'UTC'})));
    };

    const generateCSVzip = async (buffer, csvDirectory) => {
        if (!buffer || !csvDirectory || buffer.length === 0) {
            return;
        }
        try {
            // Prepare the CSV data
            const csvData = buffer.map((item) => Object.values(item).join(',')).join('\n');
            buffer.length = 0;

            const now = Date.now();
            const csvFilePath = csvDirectory + 'data' + now + '.csv';
            const zipFilePath = appConfig.zipFilePath + 'data' + now + '.zip';

            await RNFS.writeFile(csvFilePath, csvData, 'utf8');
            await zip(csvDirectory, zipFilePath);
            await RNFS.unlink(csvFilePath);

        } catch (error) {
            console.error('Error while submitting file or preparing for submission CSV or ZIP:', error);
            console.error(error.name);
            console.error(error.code);
            throw error;
        }
    };

    const uploadZipFiles = async (redcap_field) => {

        setIsSubmitting(true);
        let dirItems = await RNFS.readDir(appConfig.zipFilePath);
        console.log('files available for upload:')
        console.log(dirItems);

        let allFilesSent = true;
        for (let i = 0; i < dirItems.length; i++) {
            if (dirItems[i].isFile()) {
                console.log('submitting file... ' + dirItems[i].path)
                try {
                    await loggedInClient.importRecord(subjectId, 'sensordaten_50cee2', '<comment>' + (device?.name || 'unknown') + '</comment>', dirItems[i].path, redcap_field);
                } catch (e) {
                    allFilesSent = false;
                    setIsSubmitting(false);
                    throw e;
                }
                console.log('unlinking', dirItems[i].path);
                await RNFS.unlink(dirItems[i].path)
            }
        }
        if (allFilesSent) {
            dispatch(setBLEBufferSize(0));
        }
        setIsSubmitting(false);
    }


    if (Platform.OS === "android") {

        // BleManager.enable().then(() => {
        //     console.log('Bluetooth is turned on!');
        // });

        async function requestBluetoothPermissionsAndroid() {
            try {
                console.log('Requesting necessary permissions...');

                if (Platform.Version >= 31) { // for Android version >= 12
                    console.log('Android >= 12...');
                    // Request permission to enable Bluetooth
                    const bluetoothPermission = await PermissionsAndroid.request(
                        PermissionsAndroid.PERMISSIONS.BLUETOOTH_CONNECT
                    );

                    if (bluetoothPermission === PermissionsAndroid.RESULTS.GRANTED) {
                        console.log('BLUETOOTH_CONNECT permission granted.');

                        const bluetoothPermission = await PermissionsAndroid.request(
                            PermissionsAndroid.PERMISSIONS.BLUETOOTH_SCAN
                        );
                        if (bluetoothPermission === PermissionsAndroid.RESULTS.GRANTED) {
                            console.log('BLUETOOTH_SCAN permission granted.');

                            // Request permission to access location for Bluetooth scanning (required on Android)
                            const locationPermission = await PermissionsAndroid.request(
                                PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
                            );

                            if (locationPermission === PermissionsAndroid.RESULTS.GRANTED) {
                                setPermissionsGranted(true);
                                console.log('Location permission granted.');

                                // Now you can start using Bluetooth functionality here
                                // Initialize BleManager, scan for devices, etc.
                            } else {
                                console.log('Location permission denied.');

                                setModalTitle("Notwendige Berechtigungen nicht erteilt.")
                                setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                                setDialogVisible(true);
                            }
                        }
                    } else {

                        setModalTitle("Notwendige Berechtigungen nicht erteilt.")
                        setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                        setDialogVisible(true);

                        console.log('Bluetooth permission denied.');
                    }
                } else { // Android version <= 11
                    console.log('Android <= 11...');
                    const bluetoothPermission = await PermissionsAndroid.request(
                        'android.permission.BLUETOOTH'
                    );
                    console.log('BLUETOOTH permission?');

                    if (bluetoothPermission === PermissionsAndroid.RESULTS.GRANTED) {
                        console.log('BLUETOOTH permission granted.');

                        const bluetoothPermission = await PermissionsAndroid.request(
                            'android.permission.BLUETOOTH_ADMIN'
                        );
                        if (bluetoothPermission === PermissionsAndroid.RESULTS.GRANTED) {
                            console.log('BLUETOOTH_ADMIN permission granted.');

                            // Request permission to access location for Bluetooth scanning (required on Android)
                            const locationPermission = await PermissionsAndroid.request(
                                PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
                            );

                            if (locationPermission === PermissionsAndroid.RESULTS.GRANTED) {
                                setPermissionsGranted(true);
                                console.log('Location permission granted.');

                                // Now you can start using Bluetooth functionality here
                                // Initialize BleManager, scan for devices, etc.
                            } else {
                                console.log('Location permission denied.');

                                setModalTitle("Notwendige Berechtigungen nicht erteilt.")
                                setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                                setDialogVisible(true);
                            }
                        }
                    } else {

                        setModalTitle("Notwendige Berechtigungen nicht erteilt.")
                        setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                        setDialogVisible(true);

                        console.log('Bluetooth permission denied.');
                    }


                }


            } catch (error) {
                console.log('Error requesting permissions:', error);

                setModalTitle("Fehler beim Beantragen der Berechtigungen")
                setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                setDialogVisible(true);
            }
        }

        useEffect(() => {
            requestBluetoothPermissionsAndroid();
        }, []);
    } else if (Platform.OS === 'ios') {
        async function requestBluetoothPermissionsIOS() {
            try {
                console.log('Requesting necessary permissions for iOS...');

                check(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL)
                    .then((result) => {
                        switch (result) {
                            case RESULTS.UNAVAILABLE:
                                console.log('This feature is not available (on this device / in this context)');
                                break;
                            case RESULTS.DENIED:
                                console.log('The permission has not been requested / is denied but requestable');
                                request(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL).then((result) => {
                                    if (result) {
                                        setPermissionsGranted(true);
                                    }
                                });
                                break;
                            case RESULTS.LIMITED:
                                console.log('The permission is limited: some actions are possible');
                                break;
                            case RESULTS.GRANTED:
                                console.log('The permission is granted');
                                setPermissionsGranted(true);
                                break;
                            case RESULTS.BLOCKED:
                                console.log('The permission is denied and not requestable anymore');

                                setModalTitle("Notwendige Berechtigungen nicht erteilt.")
                                setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                                setDialogVisible(true);
                                break;
                        }
                    })
                    .catch((error) => {
                        // …
                    });

            } catch (error) {
                console.log('Error requesting permissions:', error);

                setModalTitle("Fehler beim Beantragen der Berechtigungen")
                setModalText("Bitte erteilen Sie die notwendigen Berechtigungen in den System-Einstellungen.");
                setDialogVisible(true);
            }
        }

        useEffect(() => {
            requestBluetoothPermissionsIOS();
        }, []);
    }

    const sleep = (time) => new Promise((resolve) => setTimeout(() => resolve(), time));

    // You can do anything in your task such as network requests, timers and so on,
    // as long as it doesn't touch UI. Once your task completes (i.e. the promise is resolved),
    // React Native will go into "paused" mode (unless there are other tasks running,
    // or there is a foreground app).
    const backgroundReceivingBTDataTask = async (taskDataArguments) => {

        console.log('backgroundReceivingBTDataTask')
        // Example of an infinite loop task
        const {delay} = taskDataArguments;
        await new Promise(async (resolve) => {
            console.log('backgroundReceivingBTDataTask Promise')
            while (BackgroundService.isRunning()) {
                try {
                    console.log("intensive task running");

                    const characteristic = await device.readCharacteristicForService(
                        mmServiceUUID, streamingCharacteristicUUID
                    );
                    if (characteristic) {
                        const binaryString = base64.decode(characteristic.value)
                        // console.log('streaming characteristic reading');
                        // console.log(binaryString);
                        const bytes = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) {
                            bytes[i] = binaryString.charCodeAt(i);
                        }
                        // console.log(bytes);
                    } else {
                        console.error('characteristic is null')
                    }

                    if (lastStreamingTimestamp != null) {
                        const now = new Date();
                        if (now.getTime() - lastStreamingTimestamp.getTime() > delay / 2) {
                            deviceState = 'not_streaming';
                            dispatch(setBLEDeviceState(deviceState));

                            displayNotification('Achtung', 'Der Moodmetric Ring wird seit' + lastStreamingTimestamp + ' nicht am Finger getragen oder sendet keine Daten!',
                                {
                                    notificationId: NOTIFICATION_ID_NODATA_RECEIVED,
                                    repeatInterval: 5 * 60 * 1000,
                                    delaySeconds: 120,
                                    channelId: "umfragetoolimportant",
                                    soundName: 'alert.mp3',
                                });
                            dispatch(addToEventBuffer('BLE not sending ' + BLEdevice?.name + ' ' + BLEdevice?.id));
                            generateCSVzip(dataBuffer, appConfig.csvFilePath)
                                .then(() => {
                                    uploadZipFiles(REDCAP_FIELD);
                                });
                            console.log('WE ARE NOT STREAMING');
                            console.log(deviceState);
                        } else {

                            if (deviceState != 'receiving') {
                                displayNotification('', 'Daten werden vom Moodmetric Ring empfangen',
                                    {
                                        notificationId: NOTIFICATION_ID_RECEIVING_DATA_AGAIN,
                                        timeout: 5000
                                    });
                                dispatch(addToEventBuffer('BLE receiving ' + BLEdevice?.name + ' ' + BLEdevice?.id));
                                console.log('WE ARE STREAMING AGAIN, previous deviceState', deviceState);
                            }
                            deviceState = 'receiving'
                            dispatch(setBLEDeviceState(deviceState));
                            cancelNotification(NOTIFICATION_ID_NODATA_RECEIVED);

                        }
                    } else {
                        deviceState = 'disconnected';
                        dispatch(setBLEDeviceState(deviceState));
                    }

                    await readBatteryLevel(device);
                    await readRSSI(device);
                } catch (error) {
                    console.log('Error discovering services and characteristics:', error);

                    if (!disconnectRequest) {
                        setModalTitle("Fehler beim Verbinden mit dem Gerät")
                        setModalText("Bitte versuchen Sie es erneut.");
                        setDialogVisible(true);
                    }
                }
                await sleep(delay);
            }


        });
    };

    const options = {
        taskName: 'BT Data',
        taskTitle: 'Umfragetool UK Bonn',
        taskDesc: 'Beginn der Datenaufzeichnung vom Moodmetric-Ring',
        taskIcon: {
            name: 'push_notification_icon',
            type: 'drawable',
        },
        color: theme.colors.success,

        // linkingURI: 'yourSchemeHere://chat/jane', // See Deep Linking for more info
        parameters: {
            delay: 5000,
        },
    };


    const startReceivingBTData = async (taskDataArguments) => {
        console.log('startReceivingBTData');
        console.log('starting background service 1')
        if (!backgroundServiceStarted) {
            backgroundServiceStarted = true;
            console.log('starting background service 2')
            await BackgroundService.start(backgroundReceivingBTDataTask, options);
        }
        await readBatteryLevel(device);
// iOS will also run everything here in the background until .stop() is called
//         await BackgroundService.stop();
    };

    useEffect(() => {
        if (!permissionsGranted) {
            console.log('Necessary permissions not granted!')
            return;
        }
        console.log('Starting device scan.')
        if (appConfig.bleManager === null) {

            appConfig.bleManager = new BleManager({
                restoreStateIdentifier: 'BleInTheBackground',
                restoreStateFunction: restoredState => {
                    if (restoredState == null) {
                        // BleManager was constructed for the first time.
                    } else {
                        // BleManager was restored. Check `restoredState.connectedPeripherals` property.
                    }
                },
            });
            // setManager(newManager);
        }
        const startScan = () => {
            if (scanStarted) {
                return;
            }
            scanStarted = true;
            console.log('scanning started');
            appConfig.bleManager.startDeviceScan(null, null, (error, device) => {
                console.log('scanStarted ' + scanStarted);
                if (!scanStarted) {
                    return;
                }
                if (error) {

                    console.log('Error scanning for BLE devices', error);
                    console.log('Error scanning for BLE devices reason', error.reason);

                    setModalTitle("Error scanning for BLE devices")
                    setModalText(error + error.reason);
                    setDialogVisible(true);
                    return;
                }

                console.log('Device found ' + device.serviceUUIDs);
                if (device.serviceUUIDs != null && device.serviceUUIDs.indexOf(mmServiceUUID) !== -1) {
                    setBleDevices(oldDevices => {
                        if (!oldDevices.some((d) => d.id === device.id)) {
                            return [...oldDevices, device];
                        } else {
                            return oldDevices;
                        }
                    });
                }
            });

            // return () => {
            //     newManager.stopDeviceScan();
            // };
        }

        appConfig.bleManager.onStateChange((state) => {
            // console.log('BLE onStateChange');
            if (state === 'PoweredOn') {
                startScan();
            }
            setBleAdapterState(state);
        })
        appConfig.bleManager.state().then((state) => {
            // console.log('state exploration');
            setBleAdapterState(state);
            if (state === 'PoweredOn') {
                startScan();
            }
        });


    }, [permissionsGranted]); // FIXME do we need bleDevices here_

    useEffect(
        () => {
            navigation.removeListener('beforeRemove')
            navigation.addListener('beforeRemove', (e) => {

                appConfig.bleManager?.stopDeviceScan();
                scanStarted = false;
                console.log('stopped device scan')

                navigation.dispatch(e.data.action);
            })
        },
        [navigation, appConfig.bleManager]
    );

    const MAX_RETRIES = 50;
    const RETRY_INTERVAL = 10000; // 10 seconds
    const reconnectToDeviceWithRetry = (deviceId, retries) => {
        console.log('retrying...')
        console.log('Device was disconnected, trying to reconnect...' + (MAX_RETRIES - retries + 1));
        if (retries <= 0) {
            console.log('Maximum reconnection attempts reached. Giving up.');
            // Handle the case when maximum retries are reached, if needed.
            isReconnecting = false;
            return;
        }

        deviceState = 'disconnected'
        dispatch(setBLEDeviceState(deviceState));
        displayNotification('Achtung', 'Der Moodmetric Ring scheint nicht mehr verbunden zu sein',
            {
                notificationId: NOTIFICATION_ID_DISCONNECTED,
                repeatInterval: 5 * 60 * 1000,
                channelId: "umfragetoolimportant",
                soundName: 'alert.mp3'
            });

        connectDevice(deviceId)
            .then(() => {
                isReconnecting = false;
                console.log('device successfully reconnected after ' + (MAX_RETRIES - retries + 1) + ' retries')
            })
            .catch((error) => {
                console.log('Reconnection with device ' + deviceId + '  attempt failed (' + retries + ' attempts remaining):', error.message);
                setTimeout(() => {
                    reconnectToDeviceWithRetry(deviceId, retries - 1);
                }, RETRY_INTERVAL);
            });
    };


    const connectDevice = async (deviceId) => {
        try {
            const newDevice = await appConfig.bleManager.connectToDevice(deviceId, {autoConnect: false});
            device = newDevice;
            console.log('Device connected');

            device.onDisconnected((error, device) => {
                console.log('disconnectRequest onDisconnected')
                console.log(disconnectRequest)

                if (!isReconnecting && !disconnectRequest) {
                    console.log("Device disconnected", device.id);
                    console.log(error);
                    isReconnecting = true;
                    reconnectToDeviceWithRetry(device.id, MAX_RETRIES);
                }

                generateCSVzip(dataBuffer, appConfig.csvFilePath)
                    .then(() => {
                        uploadZipFiles(REDCAP_FIELD);
                    });
            });
            await discoverServicesAndCharacteristics(newDevice);
            dispatch(setBLEDevice(device));
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const disconnectDevice = async (deviceId) => {
        try {
            await appConfig.bleManager.cancelDeviceConnection(deviceId);
            dispatch(setBLEDevice({}));
            dispatch(setBLEDeviceState(null));
            dispatch(setBLEBatteryLevel(null));
            cancelNotification(NOTIFICATION_ID_NODATA_RECEIVED);
            cancelNotification(NOTIFICATION_ID_BATTERY_LOW);
            cancelNotification(NOTIFICATION_ID_DISCONNECTED);
        } catch (error) {
            console.log('Error disconnecting device:', error);

            setModalTitle("Fehler beim Verbindungsabbruch:")
            setModalText(error + error?.reason);
            setDialogVisible(true);
            throw error;
        }
    }

    const connectOrDisconnectDevice = async (device) => {
        console.log('connectOrDisconnectDevice ' + device.id);
        if (device.id == BLEdevice?.id) {
            setDialogVisible(true);
            setModalText("Bitte warten... breche Verbindung mit Gerät ab " + device.id)
            setModalTitle("Verbindung aufheben")

            disconnectRequest = true;
            console.log('disconnectRequest disconnect')
            console.log(disconnectRequest)
            try {
                await disconnectDevice(device.id);
                dispatch(addToEventBuffer('BLE disconnected ' + BLEdevice?.name + ' ' + BLEdevice?.id));

                setModalTitle("Verbindung erfolgreich abgebrochen:")
                setModalText("Sie können diesen Dialog nun schließen.");
                setDialogVisible(true);
            } catch (error) {
                console.error('Error disconnecting device');
            }

            return;
        }

        if (BLEdevice?.id) {
            // do not allow manual connections to other devices if a device is already connected
            // console.log(BLEdevice?.id)
            return;
        }

        setDialogVisible(true);
        setModalText("Bitte warten... verbinde mit Gerät " + device.name)
        setModalTitle("Verbinden")
        try {
            disconnectRequest = false;
            console.log('disconnectRequest connect')
            console.log(disconnectRequest)
            await connectDevice(device.id);
            setDialogVisible(false);

        } catch (error) {
            console.log('Error connecting to device:', error);

            setModalTitle("Fehler beim Verbindungsaufbau:")
            setModalText(error + error?.reason);
            setDialogVisible(true);
        }
    };

    const discoverServicesAndCharacteristics = async (device) => {
        console.log('discovering step 1...')
        if (device) {
            console.log('discovering step 2...')
            try {

                await device.discoverAllServicesAndCharacteristics();
                console.log('Services and characteristics discovered');

                if (deviceState != 'receiving') {
                    displayNotification('', 'Daten werden vom Moodmetric Ring empfangen',
                        {
                            notificationId: NOTIFICATION_ID_RECEIVING_DATA_AGAIN,
                            timeout: 5000
                        });
                    dispatch(addToEventBuffer('BLE receiving ' + BLEdevice?.name + ' ' + BLEdevice?.id));
                }
                deviceState = 'receiving'
                dispatch(setBLEDeviceState(deviceState));
                cancelNotification(NOTIFICATION_ID_DISCONNECTED);
                cancelNotification(NOTIFICATION_ID_NODATA_RECEIVED);

                device.monitorCharacteristicForService(
                    mmServiceUUID,
                    streamingCharacteristicUUID,
                    (error, characteristic) => {
                        if (characteristic == null) {
                            console.error('streaming characteristic is null')
                            console.error(error);
                            return;
                        }
                        if (error) {
                            console.log(error);
                            return;
                        }
                        const binaryString = base64.decode(characteristic.value)
                        // console.log('streaming characteristic incoming');
                        // console.log(binaryString);
                        const bytes = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) {
                            bytes[i] = binaryString.charCodeAt(i);
                        }
                        // console.log(bytes);

                        lastStreamingTimestamp = new Date();
                        const timestamp = lastStreamingTimestamp.toISOString();
                        // Decode payload
                        const status = bytes[0] & 0xff;
                        const mm = bytes[1] & 0xff;
                        const instant = (bytes[2] << 8 & 0xff00) | (bytes[3] & 0xff);
                        const ax = (bytes[4] & 0xff);
                        // const ax = (bytes[4] & 0xff) * 4 / 255.0 - 2;
                        const ay = (bytes[5] & 0xff);
                        // const ay = (bytes[5] & 0xff) * 4 / 255.0 - 2;
                        const az = (bytes[6] & 0xff);
                        // const az = (bytes[6] & 0xff) * 4 / 255.0 - 2;
                        // const a = Math.sqrt(ax * ax + ay * ay + az * az);
                        const values = [timestamp, device.name, deviceBattery, deviceRSSI, status, mm, instant, ax, ay, az];
                        // console.log(values);
                        addToBuffer(dataBuffer, values, appConfig.csvFilePath);
                        if (dataBuffer.length > 0) {
                            dispatch(setBLEBufferSize(dataBuffer.length));
                        }
                    },
                );

                device.monitorCharacteristicForService(
                    mmServiceUUID,
                    rawDataCharacteristicUUID,
                    (error, characteristic) => {
                        if (characteristic == null) {
                            console.error('streaming characteristic is null')
                            console.error(error);
                            return;
                        }
                        if (error) {
                            console.log(error);
                            return;
                        }
                        const binaryString = base64.decode(characteristic.value)
                        // console.log(binaryString);
                        const bytes = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) {
                            bytes[i] = binaryString.charCodeAt(i);
                        }
                        const timestamp = new Date().toISOString();
                        // console.log('raw data incoming:');
                        // console.log(bytes);
                        addToBuffer(dataBuffer, [timestamp, device.name, bytes[0] & 0xff, bytes[1] & 0xff], appConfig.csvFilePath);
                    },
                );

                console.log('monitors set')
                await startReceivingBTData();
                // setConfirmEntryDialogVisible(true);

            } catch (error) {
                console.log('Error discovering services and characteristics:', error);

                setModalTitle("Error discovering services and characteristics:")
                setModalText(error + error?.reason);
                setDialogVisible(true);
            }
        }
    };

    const cleanup = () => {
        if (device) {
            if (characteristic) {
                characteristic
                    .removeAllListeners()
                    .then(() => console.log('Characteristic listeners removed'))
                    .catch((error) => console.log('Error removing characteristic listeners:', error));
            }
            device
                .cancelConnection()
                .then(() => console.log('Device disconnected'))
                .catch((error) => console.log('Error disconnecting from device:', error));
        }
        if (appConfig.bleManager) {
            if (Platform.OS === 'ios') {
                appConfig.bleManager.cancelDeviceConnection(DEVICE_ID)
                    .catch((error) => {
                        console.error(error);
                        throw error;
                    });
            }
            appConfig.bleManager.destroy();
            appConfig.bleManager = null;
            console.log('Manager destroyed');
        }
    };

    useEffect(() => {
        return cleanup;
    }, []);

    // internal state for the redirect modal
    const [modalState, setModalState] = useState({
        hidden: true,
        modalLink: null,
    });

    const [confirmEntryDialogVisible, setConfirmEntryDialogVisible] = useState(false);
    // const [status, setStatus] = useState(0);
    // const [x, setX] = useState(0);
    // const [y, setY] = useState(0);
    // const [z, setZ] = useState(0);
    // const [mm, setMm] = useState(0);
    // const [instant, setInstant] = useState(0);


    return loading ? (
        <Spinner/>
    ) : (

        <View style={localStyle.wrapper}>

            <InfoDialog
                navigation={navigation}
                title={modalTitle}
                text={modalText}
                dialogVisible={dialogVisible}
                setDialogVisible={setDialogVisible}
            />

            {/*<ModalDialog*/}
            {/*    navigation={navigation}*/}
            {/*    x={x}*/}
            {/*    y={y}*/}
            {/*    z={z}*/}
            {/*    mm={mm}*/}
            {/*    status={status}*/}
            {/*    instant={instant}*/}
            {/*    confirmEntryDialogVisible={confirmEntryDialogVisible}*/}
            {/*    setConfirmEntryDialogVisible={setConfirmEntryDialogVisible}*/}
            {/*/>*/}

            {/* top banner */}
            <Banner
                nav={navigation}
                title={translate('about').bleDevices.title}
                subTitle={translate('about').bleDevices.subTitle}
                noMenu
            />

            <Text>Daten zuletzt empfangen: {lastDataReceived}</Text>

            {bleAdapterState !== State.PoweredOn &&

                <View style={{width: '100%'}}>
                    <ListItem
                        containerStyle={[
                            localStyle.listItemContainerStyle
                        ]}
                    >
                        <ListItem.Content>
                            <ListItem.Title style={localStyle.btTitle}>
                                Bluetooth nicht eingeschaltet
                            </ListItem.Title>

                            <ListItem.Subtitle>Bitte schalten Sie Bluetooth auf Ihrem Gerät ein!</ListItem.Subtitle>
                        </ListItem.Content>

                        <ListItem.Chevron
                            type="material-community"
                            size={24}
                            raised
                            containerStyle={{backgroundColor: theme.colors.white}}
                            // get additional properties based on the state of the questionnaire
                            iconProps={{
                                name: 'bluetooth',
                                color: theme.colors.success,
                            }}
                        />
                    </ListItem>
                </View>
            }
            {bleAdapterState == State.PoweredOn &&
                <ListItem
                    key={8888}
                    containerStyle={localStyle.listItemContainerStyle}
                >
                    {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                    <ListItem.Content>
                        <ListItem.Title style={localStyle.btTitle}>
                            Suche läuft... es werden nur unterstützte Geräte angezeigt.
                        </ListItem.Title>

                        <ListItem.Subtitle>
                            Anzahl gefundener Geräte: {Object.keys(bleDevices).length}
                        </ListItem.Subtitle>
                    </ListItem.Content>
                </ListItem>
            }

            {/* the modal to be opened */}
            <RedirectModal
                showModal={!modalState.hidden}
                modalLink={modalState.modalLink}
                hideModal={() => {
                    setModalState({hidden: true, modalLink: null});
                }}
            />

            {/* ScrollView with content */}
            <View style={localStyle.wrapper}>


                <ScrollIndicatorWrapper>
                    <View style={localStyle.wrapper}>


                        {/* Connected device */}
                        {BLEdevice?.id &&
                            <ListItem
                                key={9999}
                                containerStyle={localStyle.containerStyle}
                                onPress={() => connectOrDisconnectDevice(BLEdevice)}
                            >
                                {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                                <ListItem.Content>
                                    <ListItem.Title style={localStyle.title}>
                                        (Verbunden) {BLEdevice.name}
                                    </ListItem.Title>

                                    <ListItem.Subtitle style={localStyle.subTitleConnected}>
                                        {BLEdevice.id}, Akku: {deviceBatteryLevel}%
                                    </ListItem.Subtitle>
                                </ListItem.Content>

                                <ListItem.Chevron
                                    type="material-community"
                                    size={24}
                                    raised
                                    containerStyle={{backgroundColor: theme.colors.white}}
                                    // get additional properties based on the state of the questionnaire
                                    iconProps={{
                                        name: 'bluetooth',
                                        color: theme.colors.success,
                                    }}
                                />
                            </ListItem>}

                        {(bufferSize > 0 && !BLEdevice?.id) &&
                            <View style={{width: '100%'}}>
                                <ListItem
                                    onPress={() => {
                                        if (!isSubmitting) {
                                            generateCSVzip(dataBuffer, appConfig.csvFilePath)
                                                .then(() => {
                                                    uploadZipFiles(REDCAP_FIELD);
                                                });
                                        }
                                    }}
                                    containerStyle={[
                                        localStyle.listItemContainerStyle
                                    ]}
                                >
                                    <ListItem.Content>
                                        {/* shows a special title for first-time-users or the regular title for all other users */}
                                        <ListItem.Title style={localStyle.errorTitle}>
                                            Nicht abgeschickte Datensätze
                                        </ListItem.Title>

                                        {/* subtitle with formatted due date of the questionnaire */}
                                        <ListItem.Subtitle
                                        >

                                            {!isSubmitting && <>
                                                Es sind noch nicht abgeschickte Datensätze vorhanden. Bitte schicken Sie
                                                sie
                                                jetzt ab (diese Meldung tappen)!</>}

                                            {isSubmitting && <>
                                                Bitte warten... Datensätze werden abgeschickt.</>}


                                        </ListItem.Subtitle>
                                    </ListItem.Content>

                                    <ListItem.Chevron
                                        type="material-community"
                                        size={24}
                                        raised
                                        containerStyle={{backgroundColor: theme.colors.white}}
                                        // get additional properties based on the state of the questionnaire
                                        iconProps={{
                                            name: 'bluetooth',
                                            color: theme.colors.success,
                                        }}
                                    />
                                </ListItem>
                            </View>
                        }


                        {/* Found devices, except the currently connected one */}
                        {Object.keys(bleDevices).map((idx) => {
                            return (

                                (BLEdevice?.id != bleDevices[idx].id &&
                                    <ListItem
                                        key={idx}
                                        containerStyle={localStyle.containerStyle}
                                        onPress={() => connectOrDisconnectDevice(bleDevices[idx])}
                                    >
                                        {/* title & subtitle of the listItem - the strings a identified by the webView*/}
                                        <ListItem.Content>
                                            <ListItem.Title style={localStyle.title}>
                                                {BLEdevice?.id == bleDevices[idx].id && <>(Verbunden)</>} {bleDevices[idx].name}
                                            </ListItem.Title>

                                            <ListItem.Subtitle style={
                                                BLEdevice?.id == bleDevices[idx].id ?
                                                    localStyle.subTitleConnected : localStyle.subTitle}>
                                                {bleDevices[idx].id}, {bleDevices[idx].rssi} dBm

                                            </ListItem.Subtitle>
                                        </ListItem.Content>


                                        {BLEdevice?.id == bleDevices[idx].id &&
                                            <ListItem.Chevron
                                                type="material-community"
                                                size={24}
                                                raised
                                                containerStyle={{backgroundColor: theme.colors.white}}
                                                // get additional properties based on the state of the questionnaire
                                                iconProps={{
                                                    name: 'bluetooth',
                                                    color: theme.colors.success,
                                                }}
                                            />}
                                    </ListItem>


                                ))
                                ;
                        })}
                    </View>
                </ScrollIndicatorWrapper>
            </View>
        </View>
    )
        ;
}

BLEManagerScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 local styling
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors.accent0,
    },

    bottom: {
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: 36,
        height: '100%',
        marginTop: 5,
        width: '100%',
        padding: 15,
    },

    button: {
        ...theme.classes.buttonPrimary,
        bottom: 0,
        marginTop: 10,
    },

    buttonAlert: {
        ...theme.classes.buttonAlert,
        bottom: 0,
        marginTop: 20,
    },

    buttonLabel: {
        ...theme.classes.buttonLabel,
    },

    containerStyle: {
        width: '100%',
        borderBottomColor: theme.colors.accent3,
        borderBottomWidth: 1,
        backgroundColor: theme.values.defaultListLinkBackgroundColor,
        padding: 15,
    },

    listItemContainerStyle: {
        width: '100%',
        padding: appConfig.scaleUiFkt(30),
    },

    subTitle: {
        color: theme.colors.accent4,
        ...theme.fonts.body,
    },


    subTitleConnected: {
        color: theme.colors.success,
        ...theme.fonts.body,
    },

    warningSubTitle: {
        color: theme.colors.alert,
        ...theme.fonts.body,
    },

    title: {
        ...theme.fonts.title2,
    },

    titleText: {
        width: '80%',
        textAlign: 'center',
        alignSelf: 'center',
        ...theme.fonts.header2,
    },


    btTitle: {
        ...theme.fonts.header3,
        color: theme.values.defaultCheckInListViewTitleColor,
    },
    errorTitle: {
        ...theme.fonts.header3,
        color: theme.colors.no,
    },


    languagePickerWrapper: {
        borderWidth: 3,
        borderColor: theme.colors.white,
        borderRadius: 4,
        padding: 10,
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default BLEManagerScreen;
