import * as React from "react";
import {Dimensions, View, Text, StyleSheet} from "react-native";

import {theme} from "../config";

import translate from "../services/localization";


const InternetConnectionFail = () => {

    return (
        <View
            style={[localStyle.mainButton]}
        >
            <View style={{ paddingRight: 40 }}>
                <View style={{ width: '100%' }}>
                    <Text style={{...theme.fonts.title2, color: theme.colors.success, marginBottom: 8, marginTop: 0}}>{translate('generic').errorNoInternetTitle}</Text>
                    <Text style={{...theme.fonts.body, paddingRight: 0, color: theme.values.defaultTitleTextColor}}>{translate('generic').errorNoInternet}</Text>
                </View>
            </View>

        </View>
    )
};

const windowWidth = Dimensions.get("window").width;

const localStyle = StyleSheet.create({

    mainButton: {
        marginLeft: 20,
        marginRight: 20,
        paddingLeft: 20,

        paddingRight: 20,
        marginBottom: 15,
        flex: 1,
        flexDirection: 'row',
        borderRadius: 20,
        borderWidth: 0,
        backgroundColor: '#EDEDED',
        paddingTop: 20,
        paddingBottom: 15
    },
});

export default InternetConnectionFail;
