/**
 * enum for all route names
 */
const Routes = {
  LANDING: 'Landing',
  LOGIN: 'Login',
  CHECK_IN: 'CheckIn',
  SURVEY: 'Survey',
  ABOUT: 'About',
  SIMPLE_ABOUT: 'SimpleAbout',
  WEBVIEW: 'Webview',
  WALKTHROUGH: 'Walkthrough',
  TERMS_CONDITIONS: 'Terms',
  BLE: 'BLE',
  TIME: 'Time',
  LOAD_NEW_VERSION: 'LoadNewVersion',
  MAINTENANCE_MODE: 'MaintenanceMode',


};

/**
 * enum for all stacks
 */
const Stacks = {
  SIGNED_IN: 'SignedIn',
  SIGNED_OUT: 'SignedOut',
  FORCE_NEW_VERSION: 'ForceNewVersion',
};

export { Routes, Stacks };
