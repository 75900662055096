// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Text, Platform, Image, Linking} from 'react-native';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

import { appConfig, theme } from '../config';
import {Button} from "react-native-paper";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Stacks} from "../navigation/constants";
import VersionCheck from '../components/shared/VersionCheck';
import {versionCheck} from "../services/utils";
import {useRoute} from "@react-navigation/native";
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component:
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function MaintenanceModeScreen({ navigation }) {
  const dispatch = useDispatch();
  const route = useRoute();
  let version = VersionCheck.getCurrentVersion() + ", Build " + VersionCheck.getCurrentBuildNumber();

  const { subjectId } = useSelector((state) => state.User);

  useEffect(() => {
    setTimeout(() => versionCheck(navigation, dispatch, route, subjectId), 30000);
  }, [])


  return (
      <View style={[localStyle.wrapper, {alignItems: 'center'}]}>
        <Image
            style={localStyle.banner}
            resizeMode="contain"
            source={require('../assets/images/defaultLogo.png')}
        ></Image>
        <Text style={localStyle.titleText}>
          Wartungsarbeiten
        </Text>
        <Text style={localStyle.infoText}>
          Wir möchten Sie informieren, dass wir derzeit planmäßige Wartungsarbeiten durchführen, um Ihre App-Erfahrung zu verbessern.
        </Text>
        <Text style={localStyle.bodyText}>
          Während dieser Zeit wird die App vorübergehend nicht verfügbar sein. Wir entschuldigen uns für eventuelle Unannehmlichkeiten und danken Ihnen für Ihr Verständnis.
          {'\n\n'}
          Vielen Dank für Ihre Geduld und Ihr Vertrauen.
        </Text>

        <Text style={localStyle.versionText}>{version}</Text>
      </View>
  );
}

MaintenanceModeScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    height: '100%',
    width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
  },


  banner: {
    marginTop: 50,
    marginBottom: 30,
    width: '80%',
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBannerBackgroundColor,
  },


  flexi: {
    flex: 1,
  },

  title: {
    ...theme.fonts.title,
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.values.defaultTitleTextColor,
  },

  top: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: appConfig.scaleUiFkt(15),
    marginBottom: appConfig.scaleUiFkt(35),
  },

  infoText: {
    textAlign: 'justify',
    alignSelf: 'auto',
    color: theme.colors.accent4,
    ...theme.fonts.label,

    marginTop: appConfig.scaleUiFkt(20),
    marginBottom: appConfig.scaleUiFkt(20),
    marginLeft: appConfig.scaleUiFkt(40),
    marginRight: appConfig.scaleUiFkt(40),
  },

  bodyText: {
    textAlign: 'justify',
    alignSelf: 'auto',
    color: theme.colors.accent4,
    ...theme.fonts.hint,

    marginTop: appConfig.scaleUiFkt(20),
    marginBottom: appConfig.scaleUiFkt(20),
    marginLeft: appConfig.scaleUiFkt(40),
    marginRight: appConfig.scaleUiFkt(40),
  },

  buttonText: {
    marginTop: appConfig.scaleUiFkt(20),
    marginBottom: appConfig.scaleUiFkt(20),
    marginLeft: appConfig.scaleUiFkt(40),
    marginRight: appConfig.scaleUiFkt(40),
    textAlign: 'justify',
    alignSelf: 'auto',
    color: theme.colors.success,
    ...theme.fonts.title2,
  },

  versionText: {
    marginTop: appConfig.scaleUiFkt(20),
    marginBottom: appConfig.scaleUiFkt(20),
    marginLeft: appConfig.scaleUiFkt(40),
    marginRight: appConfig.scaleUiFkt(40),
    textAlign: 'justify',
    alignSelf: 'auto',
    color: theme.colors.accent1,
    ...theme.fonts.hint,
  },

  titleText: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
    ...theme.fonts.title,
  },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default MaintenanceModeScreen;
