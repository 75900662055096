import React, {useEffect, useState} from 'react';
import {Alert, StyleSheet, Text, TouchableOpacity, View, Platform, useWindowDimensions} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// components
import {Banner, ScrollIndicatorWrapper, Spinner} from '../components/shared';
import QuestionnaireModal from '../components/questionnaireModal/questionnaireModal';
import CategoriesList from '../components/survey/categoriesList';

// services
import translate from '../services/localization';
import {theme} from '../config';
import exportService from '../services/questionnaireAnalyzer';

// redux actions
import {fetchQuestionnaire, switchContent} from '../store/questionnaire.slice';
import {sendQuestionnaireResponse} from '../store/sharedActions';

import {Routes} from '../navigation/constants';
import NetInfo, {useNetInfo} from "@react-native-community/netinfo";
import endpoints from "../services/rest/endpoints";

import { Dialog } from 'react-native-simple-dialogs';
import { Button } from 'react-native-paper';
import {isMobile} from "react-device-detect";
import {markdownToHtml} from '../services/utils';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * renders the survey-screen with the list of all categories, the modal to answer the questionnaire
 * and a button to send the response to the server
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function SurveyScreen({navigation}) {
    const dispatch = useDispatch();

    const {itemMap, categories, FHIRmetadata} = useSelector(
        (state) => state.Questionnaire,
    );
    const {loading, error} = useSelector((state) => state.Globals);
    const {current_questionnaire_id, subjectId, start_date, questionnaire_hint} = useSelector(
        (state) => state.User,
    );

    // information needed for different dialogs to be shown to user (for web as alternative to Alert)
    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogButtons, setDialogButtons] = useState([]);

    NetInfo.configure({
        reachabilityUrl: endpoints.ping,
        reachabilityTest: async (response) => response.status === 204,
        reachabilityLongTimeout: 60 * 1000, // 60s
        reachabilityShortTimeout: 5 * 1000, // 5s
        reachabilityRequestTimeout: 3 * 1000, // 15s
        reachabilityShouldRun: () => true,
        shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
        useNativeReachability: true
    });
    const netInfo = useNetInfo();

    // when the component loads and no categories are present - i.e. no questionnaire has previously been fetched
    // get questionnaire from backend if no error ocurred before
    useEffect(() => {
        console.log("CALCULATION 2");
        if (!categories && !error && !loading) {
            if (new Date() > new Date(start_date)) {
                dispatch(
                    fetchQuestionnaire({
                        questionnaireID: current_questionnaire_id,
                        subjectId,
                    }),
                );
            } else {
                navigation.navigate(Routes.CHECK_IN);
            }
        }
    }, [
        categories,
        dispatch,
        error,
        loading,
        current_questionnaire_id,
        subjectId,
        navigation,
        start_date,
    ]);

    // check if all categories of the questionnaire have been answered as required
    const done = categories?.every((category) => itemMap[category.linkId].done);

    /**
     * handle submission of questionnaire
     */
    const handleSubmit = () => {
        if (Platform.OS === 'native') {
            Alert.alert(
                translate('generic').info,
                translate('survey').sendFinishedMessage,
                [
                    {
                        text: translate('survey').sendFinished,
                        onPress: () => {

                            if (NetInfo.refresh().then(state => {
                                if (state.isInternetReachable) {
                                    dispatch(
                                        sendQuestionnaireResponse({
                                            body: exportService.createResponseJSON(
                                                itemMap,
                                                categories,
                                                FHIRmetadata,
                                            ),
                                        }),
                                    );
                                    navigation.navigate(Routes.CHECK_IN);
                                } else {
                                    Alert.alert(
                                        translate('generic').errorNoInternetTitle,
                                        translate('generic').errorNoInternet,
                                        [
                                            {
                                                text: translate('generic').ok,
                                            },
                                        ],
                                        {cancelable: false},
                                    );
                                }
                            })) ;


                        },
                    },
                    {
                        text: translate('generic').abort,
                        style: 'cancel',
                    },
                ],
                {cancelable: false},
            );
        } else {
            setDialogTitle(translate('generic').info);
            setDialogMessage(translate('survey').sendFinishedMessage);
            setDialogButtons([
            {
                mode: "contained",
                text: translate('survey').sendFinished,
                onPress: () => {
                    dispatch(
                        sendQuestionnaireResponse({
                        body: exportService.createResponseJSON(
                            itemMap,
                            categories,
                            FHIRmetadata,
                        ),
                        }),
                    );
                    setShowDialog(false);
                    navigation.navigate(Routes.CHECK_IN);
                }
            },
            {
                mode: "outlined",
                text: translate('generic').abort,
                style: 'cancel',
                onPress: () => setShowDialog(false),
            },
            ]);
            setShowDialog(true);
        }
    };


    let totalQuestions = 0;
    let mandatoryQuestions = 0;
    let mandatoryCompletedQuestions = 0;
    let completedQuestions = 0;
    for (const i in itemMap) {
        totalQuestions += itemMap[i].type !== 'display' && itemMap[i].type !== 'group' ? 1 : 0;
        mandatoryQuestions += itemMap[i].required && itemMap[i].type !== 'display' && itemMap[i].type !== 'group' ? 1 : 0;
        mandatoryCompletedQuestions += itemMap[i].done && itemMap[i].required && itemMap[i].type !== 'display' && itemMap[i].type !== 'group' ? 1 : 0;
        completedQuestions += itemMap[i].done && itemMap[i].type !== 'display' && itemMap[i].type !== 'group' ? 1 : 0;
    }
    console.log("CALCULATION 1");
    let {width} = useWindowDimensions();

    return loading ? (
        <Spinner/>
    ) : (
        <View style={[localStyle.flexi, localStyle.wrapper]} testID="SurveyScreen">
            {/* render the top banner */}
            <Banner nav={navigation} title={translate('survey').title}/>

            {/* the questionnaire modal */}
            <QuestionnaireModal nav={navigation} />

            {/* Dialog to replace Alert.alert for web */}
            <Dialog
                visible={showDialog}
                title={dialogTitle}
                onTouchOutside={() => setShowDialog(false)}
                dialogStyle={{ width: (Platform.OS === 'web' && isMobile ? '95%' : 800), alignSelf: 'center' }}
            >
                <View>
                <Text style={{ marginBottom: 20 }}>
                    {dialogMessage}
                </Text>
                <View style={{ flexDirection: 'column' }}>
                    {dialogButtons.map((button) => (
                    <Button
                        mode={button.mode ? button.mode : "contained"}
                        onPress={() => {
                        button.onPress ? button.onPress() : null;
                        }}
                        style={{ marginBottom: 5 }}
                    >
                        {button.text}
                    </Button>
                    ))}
                </View>
                </View>
            </Dialog>

            <ScrollIndicatorWrapper>

                <View style={{margin: 20}}>
                    <View style={localStyle.wrapperText}>
                        <View style={{ backgroundColor: '#dddddd', marginTop: 10, padding: 10 }}>
                            {!!questionnaire_hint && (
                                <RenderHtml
                                    contentWidth={width}
                                    source={{
                                        html: markdownToHtml(questionnaire_hint),
                                    }}
                                />
                            )}
                            {!questionnaire_hint && (
                                <>
                                    <Text style={localStyle.infoText}>
                                        Tippen Sie auf einen Fragebogen-Titel, um mit dem
                                        Beantworten des Fragebogens zu beginnen.{' '}
                                        {!categories?.[0]?.item[0]?.fieldAnnotation.includes(
                                            '[buttonSurvey',
                                        ) && (
                                            <>
                                                Sie können sich eine Übersicht der Fragebogeninhalte
                                                verschaffen, indem Sie auf den Pfeil rechts vom Titel
                                                des jeweiligen Fragebogens tippen.
                                            </>
                                        )}
                                    </Text>
                                </>
                            )}
                        </View>

                        {/*{!categories?.[0]?.item[0]?.fieldAnnotation.includes('[buttonSurvey') &&*/}
                        {/*    <Text style={[localStyle.infoText, {marginTop: 10}]}>*/}
                        {/*        Sie haben bislang <Text style={{fontWeight: 'bold'}}>{completedQuestions}</Text> von insg. <Text style={{fontWeight: 'bold'}}>{totalQuestions}</Text> Fragen{'\n'}*/}
                        {/*        (davon <Text style={{fontWeight: 'bold'}}>{mandatoryCompletedQuestions}</Text> von insg. <Text style={{fontWeight: 'bold'}}>{mandatoryQuestions}</Text> Pflichtfragen) beantwortet.*/}
                        {/*    </Text>}*/}
                    </View>
                </View>

                <View style={[localStyle.flexi, localStyle.wrapper]}>
                    {/* creates the list items for the categories */}
                    <CategoriesList
                        showQuestionnaireModal={(categoryIndex, pageIndex) => {
                            dispatch(switchContent({categoryIndex, pageIndex}));
                        }}
                        categories={categories}
                        itemMap={itemMap}
                        FHIRmetadata={FHIRmetadata}
                    />
                </View>
            </ScrollIndicatorWrapper>
            {/* renders a send-button at the bottom if the questionnaire is completed */}
            <View style={localStyle.bottom}>
                {done && (
                    <TouchableOpacity
                        accessibilityLabel={translate('survey').send}
                        accessibilityRole={translate('accessibility').types.button}
                        accessibilityHint={
                            translate('accessibility').questionnaire.sendHint
                        }
                        onPress={handleSubmit}
                        style={[localStyle.button, localStyle.buttonComplete]}
                    >
                        <Text style={localStyle.buttonLabel}>
                            {translate('survey').send}
                        </Text>
                    </TouchableOpacity>
                )}
                {/* renders a button to retry fetching of questionnaire */}
                {error?.failedAction === 'questionnaire/FETCH' && (
                    <TouchableOpacity
                        accessibilityLabel={translate('login').landing.retry}
                        accessibilityRole={translate('accessibility').types.button}
                        accessibilityHint={translate('accessibility').refreshHint}
                        onPress={() =>
                            dispatch(
                                fetchQuestionnaire({
                                    questionnaireID: current_questionnaire_id,
                                    subjectId,
                                }),
                            )
                        }
                        style={[localStyle.button, localStyle.buttonAlert]}
                    >
                        <Text style={localStyle.buttonLabel}>
                            {translate('login').landing.retry}
                        </Text>
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );
}

SurveyScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        backgroundColor: theme.values.defaultBackgroundColor,
        width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
        alignSelf: 'center',
    },

    wrapperText: {
        backgroundColor: theme.values.defaultBackgroundColor,
        width: '100%'
    },

    flexi: {
        flex: 1,
    },

    bottom: {
        alignSelf: 'flex-end',
        justifyContent: 'center',
        flex: 1 / 6,
        width: '100%',
    },

    bottomHidden: {
        display: 'none',
    },

    button: {
        ...theme.classes.buttonPrimary,
        bottom: 0,
        width: '80%',
        textAlign: 'center',
    },

    buttonComplete: {
        backgroundColor: theme.values.defaultSendQuestionnaireButtonBackgroundColor,
    },

    buttonAlert: {
        backgroundColor: theme.colors.alert,
    },

    buttonLabel: {
        ...theme.classes.buttonLabel,
    },

    infoText: {
        textAlign: 'center',
        alignSelf: 'center',
        color: theme.colors.accent4,
        ...theme.fonts.hint,
    },

});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default SurveyScreen;
