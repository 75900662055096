// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

import AboutScreen from '../screens/aboutScreen';
import WebViewScreen from '../screens/webViewScreen';
import LegalInformationScreen from '../screens/legalInformationScreen';
import LandingScreen from '../screens/landingScreen';
import LoginScreen from '../screens/loginScreen';
import CheckInScreen from '../screens/checkInScreen';
import SurveyScreen from '../screens/surveyScreen';
import WalkthroughScreen from '../screens/walkthroughScreen';
import BLEManagerScreen from "../screens/bleManagerScreen";
import MaintenanceModeScreen from "../screens/maintenanceModeScreen";

import {Routes, Stacks} from './constants';
import { Platform } from 'react-native';
import TimeScreen from "../screens/timeScreen";
import SimpleAboutScreen from "../screens/simpleAboutScreen";
// import { c } from 'tar';

/***********************************************************************************************
 export and AppContainer-creation
 ***********************************************************************************************/

// creates the stack-navigator for the navigation while NOT LOGGED IN


const title = (text) => Platform.select({ web: `${text} | Umfragetool UK Bonn`, default: text });

function SignedOutView() {
    const Stack = createNativeStackNavigator();
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
        }}
        // initialRouteName={Routes.LANDING}
        initialRouteName={Routes.LOGIN}
      >
        <Stack.Screen name={Routes.LANDING} component={LandingScreen} options={{title: title('Willkommen') }} />
        <Stack.Screen
          name={Routes.MAINTENANCE_MODE}
          options={{title: title('Wartung') }}
          component={MaintenanceModeScreen}
        />
        <Stack.Screen name={Routes.LOGIN} component={LoginScreen} options={{ title: title('Anmeldung') }} />
        <Stack.Screen name={Routes.WEBVIEW} component={WebViewScreen} options={{title: title('Info') }} />
          <Stack.Screen
              name={Routes.TERMS_CONDITIONS}
              options={{title: title('Nutzungsbedingungen') }}
              component={LegalInformationScreen}
          />
      </Stack.Navigator>
    );
}

// creates the stack-navigator for the navigation while LOGGED IN

function SignedInView() {
    const Stack = createNativeStackNavigator();
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                gestureEnabled: false,
            }}
            initialRouteName={Routes.CHECK_IN}
        >
            <Stack.Screen name={Routes.CHECK_IN} options={{title: title('Willkommen') }} component={CheckInScreen}/>
            <Stack.Screen name={Routes.SURVEY} options={{title: title('Fragebögen') }} component={SurveyScreen}/>
            <Stack.Screen name={Routes.ABOUT} options={{title: title('Über') }} component={AboutScreen}/>
            <Stack.Screen name={Routes.WEBVIEW} options={{title: title('Info') }} component={WebViewScreen}/>
            {/* // TODO: WalkthroughScreen is not yet implemented for web */}
            <Stack.Screen name={Routes.WALKTHROUGH} options={{title: title('Einführung') }} component={WalkthroughScreen}/>
            {/* <Stack.Screen name={Routes.BLE} component={BLEManagerScreen}/>
            <Stack.Screen name={Routes.TIME} component={TimeScreen}/> */}
            <Stack.Screen
                name={Routes.TERMS_CONDITIONS}
                options={{title: title('Nutzungsbedingungen') }}
                component={LegalInformationScreen}
            />
        </Stack.Navigator>
    );
}

function ForceNewVersionView() {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
      initialRouteName={Routes.MAINTENANCE_MODE}
    >
      <Stack.Screen
        name={Routes.MAINTENANCE_MODE}
        component={MaintenanceModeScreen}
      />
    </Stack.Navigator>
  );
}

const linking = {
  config: {
    screens: {
      [Stacks.SIGNED_IN]: 'user',
      [Stacks.SIGNED_OUT]: '',
      [Routes.CHECK_IN]: 'check-in',
      [Routes.ABOUT]: 'about',
      [Routes.SIMPLE_ABOUT]: 'simple-about',
      [Routes.WEBVIEW]: 'webview',
      [Routes.TERMS_CONDITIONS]: 'terms',
      [Routes.BLE]: 'ble',
      [Routes.TIME]: 'time',
      [Routes.LANDING]: 'landing',
      [Routes.LOGIN]: 'login',
      [Routes.SURVEY]: 'survey',
      [Routes.MAINTENANCE_MODE]: 'maintenance',
    },
  },
};

/**
 * creates the app container based on the two stack-navigators
 */
function AppNavigator() {
    const RootStack = createNativeStackNavigator();
    return (
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <RootStack.Navigator
          screenOptions={{
            headerShown: false,
            gestureEnabled: false,
          }}
          initialRouteName={Stacks.SIGNED_OUT}
        >
          <RootStack.Screen
            name={Routes.SIMPLE_ABOUT}
            options={{title: title('Über') }}
            component={SimpleAboutScreen}
          />
          <RootStack.Screen
            name={Routes.TERMS_CONDITIONS}
            options={{title: title('Nutzungsbedingungen') }}
            component={LegalInformationScreen}
          />
          <RootStack.Screen
            name={Stacks.FORCE_NEW_VERSION}
            options={{title: title('Neue Version verfügbar') }}
            component={ForceNewVersionView}
          />
          <RootStack.Screen name={Stacks.SIGNED_IN} component={SignedInView} />
          <RootStack.Screen
            name={Stacks.SIGNED_OUT}
            component={SignedOutView}
            options={{title: title('Anmeldung') }}
          />
        </RootStack.Navigator>
      </NavigationContainer>
    );
}

export default AppNavigator;
