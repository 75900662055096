import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';

const RoundedButton = ({onPress, text, shaded= false}) => {
    return (
        <TouchableOpacity style={ [styles.button, shaded ? styles.buttonShaded : styles.button]} onPress={onPress}>
            <Text style={[styles.text, shaded ? styles.textShaded : styles.text]}>{text}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#007AFF',
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: 15,
        marginBottom: 10,
        marginTop: 3,
        marginRight: 5
    },
    buttonShaded: {
        backgroundColor: '#007AFF',
    },
    text: {
        color: '#007AFF',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
    },
    textShaded: {
        color: '#fff'
    }
});

export default RoundedButton;
