// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useState, useEffect, useRef} from 'react';
import {
    AccessibilityInfo,
    StyleSheet,
    TouchableOpacity,
    Platform,
    Dimensions,
    ScrollView,
    KeyboardAvoidingView,
    Text, View,
    Animated, Image, Keyboard
} from 'react-native';
import PropTypes from 'prop-types';

// components
import { Icon } from '@rneui/themed';

// services & config
import {appConfig, theme} from '../../config';
import translate from '../../services/localization';
import {KeyboardAwareScrollView} from './KeyboardAwareScrollView';

/***********************************************************************************************
 * component
 * wraps a scrollView and shows an indicator button when there is something to scroll
 * when clicking that indicator button the scroll view is scroll by the height of the viewport
 *
 * @param {object}          props
 * @param {[JSX.Element]}   props.children the content to be wrapped in this component
 **********************************************************************************************/
export default function ScrollIndicatorWrapper({children, rescroll, smallStepScroll}) {
    // internal state
    const [showIndicator, setShowIndicator] = useState(false);
    const [isAccessibilityOn, setIsAccessibilityOn] = useState(false);

    const scrollViewRef = useRef();

    let nextScrollValue = useRef(0);

    // setup when component is mounted
    useEffect(() => {
        AccessibilityInfo.isScreenReaderEnabled().then((screenReaderEnabled) => {
            setIsAccessibilityOn(screenReaderEnabled);
        });
    }, []);


    const slideAnim = useRef(new Animated.Value(0)).current;

    const slideUp = Animated.timing(slideAnim, {
        toValue: -100, // Adjust the distance to slide up
        duration: 2000, // Adjust the duration as needed
        useNativeDriver: true,
    });

    const slideDown = Animated.timing(slideAnim, {
        toValue: -70,
        duration: 2000, // Adjust the duration as needed
        useNativeDriver: true,
    });

    const startAnimation = () => {
        Animated.sequence([slideUp, slideDown]).start(({finished}) => {
            if (finished) {
                // If the animation finished, start it again
                startAnimation();
            }
        });
    };

    useEffect(() => {
        // Start the animation when the component mounts
        startAnimation();
    }, [showIndicator]);

    const handleLayout = (event) => {
        console.log(event);
        const { height } = event.nativeEvent.layout;
        const screenHeight =  Dimensions.get('window').height;
        console.log('handle layout c');
        console.log(height)
        console.log(screenHeight);

        setShowIndicator(height > screenHeight);
    };

    return (
        <>
            {/* the regular ScrollView & content */}
            <ScrollView
                enableOnAndroid
                ref={scrollViewRef}
                style={localStyle.wrapper}
                contentContainerStyle={localStyle.contentContainerStyle}
                scrollEventThrottle={16}
                onScroll={(event) => {
                    // calculating the next scroll-position
                    const bannerHeight =
                        Platform.OS === 'ios'
                            ? appConfig.scaleUiFkt(290, 0.7)
                            : appConfig.scaleUiFkt(290, 0.6);
                    const contentHeight = Dimensions.get('window').height - bannerHeight;
                    let scrollValue = event.nativeEvent.contentOffset.y +
                        contentHeight -
                        localStyle.indicator.height -
                        localStyle.indicator.bottom -
                        20 - (smallStepScroll ? smallStepScroll : 0);
                    console.log('scrollValue');
                    console.log(scrollValue);
                    nextScrollValue.current = scrollValue;

                    // determines if the indicator should be displayed or not
                    setShowIndicator(
                        event.nativeEvent.layoutMeasurement.height <
                        event.nativeEvent.contentSize.height &&
                        event.nativeEvent.contentSize.height -
                        event.nativeEvent.layoutMeasurement.height -
                        event.nativeEvent.contentOffset.y >
                        30,
                    );
                }}
            >
                {/* the content */}
                <View onLayout={handleLayout}>
                    {children}
                </View>
            </ScrollView>

            {/* if "allowScrollIndicators" is true and the situation calls for a scroll indicator ("showIndicator") and
				we are not in accessibility-mode */}
            {theme.ui.allowScrollIndicators && showIndicator && !isAccessibilityOn && (
                <View style={{width: '100%', flexDirection: 'row', alignSelf: 'flex-end', justifyContent: 'flex-end'}}>
                    <TouchableOpacity
                        accessibilityRole={translate('accessibility').types.button}
                        onPress={() => {
                            // scrollViewRef.current.scrollTo({
                            //     x: 0,
                            //     y: nextScrollValue.current,
                            //     animated: true,
                            // });
                        }}
                    >
                        <Animated.View
                            style={[localStyle.scroll_indicator_hand, {
                                width: 30,
                                height: 30,
                                translateY: slideAnim,
                                opacity: 0.8
                            }]}
                        >
                            <Image source={require('../../assets/images/scrolling.png')}
                                   style={{width: 50, height: 50}}/>

                        </Animated.View>
                        {/*<Icon*/}
                        {/*    name="chevron-down"*/}
                        {/*    type="font-awesome"*/}
                        {/*    size={15}*/}
                        {/*/>*/}
                    </TouchableOpacity>
                </View>
            )}
        </>
    );
}

ScrollIndicatorWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element.isRequired,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    contentContainerStyle: {
        flexGrow: 1,
    },

    container: {
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        backgroundColor: 'blue',
        overflow: 'hidden',
    },

    wrapper: {
        flex: 1,
        flexDirection: 'column',
        width: '100%',
    },

    scroll_indicator_hand: {
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 20,
        paddingRight: 40,
        alignItems: 'center',
        alignSelf: 'flex-start',
    },

    indicator: {
        width: appConfig.scaleUiFkt(75),
        height: appConfig.scaleUiFkt(75),
    },
});
