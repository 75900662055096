// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React from 'react';
import {View, StyleSheet, Text, Platform} from 'react-native';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// custom components
import { Banner, ScrollIndicatorWrapper } from '../components/shared';

// services & config
import translate from '../services/localization';
import { appConfig, theme } from '../config';
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function LegalInformationScreen({ navigation }) {
  return (
    <View>
      <View style={localStyle.wrapper}>
        {/* banner */}
        <Banner
            nav={navigation}
            title={translate('legalInformation').title}
            subTitle={translate('legalInformation').subTitle}
            noMenu
            isCheckIn={true}
            noWayBack={true}
        />
        {/* content */}
        <View style={{ ...localStyle.flexi, ...localStyle.wrapper }}>
          <ScrollIndicatorWrapper>
            <View style={{ ...localStyle.wrapper, ...localStyle.top }}>
              <Text style={{ textAlign: 'left' }}>Durch die Installation und Nutzung dieser App ("Umfragetool UK Bonn") bzw. dieser Webseite (im Weiteren als Sammelbegriff "App" bezeichnet) erklären Sie sich mit den vorliegenden
                Nutzungsbedingungen einverstanden. Wenn Sie mit den Bedingungen nicht einverstanden
                sind, dürfen Sie die App bzw. die Webseite nicht nutzen.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>1. Begriffsbestimmungen</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Nutzer / Nutzerin: sind die Personen, die die App im Rahmen der Teilnahme an einer
                klinischen Studie bzw. einer wissenschaftlichen Studie verwenden.</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Betreiber: Betreiber ist das Universitätsklinikum Bonn (AöR)</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>2. Zweck der App:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Diese App dient der Durchführung von Probandenbefragungen im Rahmen einer klinischen
                Studie bzw. einer wissenschaftlichen Studie. Näheres zur Studie erfahren Sie in Ihrer
                Teilnahmeeinwilligung. Die Nutzung der App erfolgt pseudonymisiert und ist nur mittels
                erteilten QR-Code zur Anmeldung möglich.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>3. Verantwortung des Nutzers und der Nutzerin:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Die App darf nicht für rechtswidrige oder betrügerische Zwecke genutzt werden. Die
                Inhalte dürfen nicht vervielfältigt, gedruckt oder an Dritte weitergegeben werden.
                Nutzer und Nutzerinnen dürfen die App nur mittels des erteilten QR-Codes nutzen und
                Dritten keine Möglichkeit verschaffen, die App zu nutzen.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>4. Geistiges Eigentum:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Alle Rechte an geistigem Eigentum in Bezug auf die App und deren Inhalte
                (einschließlich Texte, Grafiken, Logos, Designs, usw.) liegen beim Betreiber oder seinen
                Lizenzgebern. Die App darf nicht ohne ausdrückliche Zustimmung modifiziert oder
                anderweitig verwendet werden.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>5. Haftungsausschluss:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Die App wurde mit der größtmöglichen Sorgfalt vom Betreiber entwickelt und getestet.
                Der Betreiber übernimmt keine Verantwortung für die Funktionalität, Vollständigkeit oder
                Zuverlässigkeit der App. Die Nutzung der App erfolgt auf eigenes Risiko.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>6. Änderungen der Nutzungsbedingungen:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Der Betreiber behält sich vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit für
                die Zukunft zu ändern oder zu aktualisieren. Über derartige Änderungen werden die Nutzer
                und Nutzerinnen mindestens 30 Tage vor dem geplanten Inkrafttreten der Änderung in
                Kenntnis gesetzt. Sofern die Nutzung der App auch nach Ablauf der 30 Tage fortgesetzt
                wird, gelten die Änderungen als wirksam vereinbart. Nutzer und Nutzerinnen, die mit den
                Änderungen der Nutzungsbedingungen nicht einverstanden sind, können die App jederzeit
                löschen.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>7. Anwendbares Recht:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland unter
                Ausschluss der Kollisionsnormen des deutschen internationalen Privatrechts und sind nach
                diesem auszulegen.</Text>
              <Text style={{marginTop: 20, textAlign: 'left', textDecorationStyle: 'solid', textDecorationLine: 'underline' }}>8. Schlussbestimmungen:</Text>
              <Text style={{marginTop: 10, textAlign: 'left' }}>Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden
                oder sollten sie den gesetzlichen Regelungen widersprechen, so wird hierdurch die
                Wirksamkeit der Nutzungsbedingungen im Übrigen nicht berührt. Tritt sein solcher Fall
                ein, wird die ungültige Bestimmung durch eine Bestimmung ersetzt, die dem
                wirtschaftlichen Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am nächsten
                kommt. Diese Herangehensweise gilt auch für Lücken dieser Nutzungsbedingungen.</Text>

            </View>
          </ScrollIndicatorWrapper>
        </View>
      </View>
    </View>
  );
}

LegalInformationScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
localStyle
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    height: '100%',
    width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },

  title: {
    ...theme.fonts.title,
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.values.defaultTitleTextColor,
  },

  top: {
    paddingStart: 20,
    paddingEnd: 20,
    justifyContent: 'left',
    alignItems: 'left',
    paddingTop: appConfig.scaleUiFkt(15),
    marginBottom: appConfig.scaleUiFkt(35),
  },

  infoText: {
    marginTop: appConfig.scaleUiFkt(20),
    marginBottom: appConfig.scaleUiFkt(20),
    marginLeft: appConfig.scaleUiFkt(40),
    marginRight: appConfig.scaleUiFkt(40),
    textAlign: 'justify',
    alignSelf: 'auto',
    color: theme.colors.accent4,
    ...theme.fonts.body,
  },

  titleText: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
    ...theme.fonts.header2,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default LegalInformationScreen;
