// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/**
 * the code contained in this file is rendering the content (meaning the ui-elements) of
 * the modal that opens when an item on the survey screen is clicked on. the user-input
 * received by these ui-elements is persisted in the object "questionnaireItemMap", located
 * in the checkIn state.
 *
 * the following terms are used in the comments in this file:
 *
 * item:
 * a single questionnaire item:
 * https://www.hl7.org/fhir/questionnaire-definitions.html#Questionnaire.item
 *
 * categories:
 * all first level items with linkIds like "1" or "6" or "15"
 * page:
 * a page is composed of all sub-items of a category that have
 * the identical value as the second position of their linkId. for example:
 * all linkIds starting with "1.2" (and "1.2.1" and "1.2.1.1" and so on) will
 * be considered a page
 */

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import {
    Alert, Dimensions,
    I18nManager, Keyboard,
    KeyboardAvoidingView, Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

// components
import RNModal from 'react-native-modal';
import {Icon} from '@rneui/themed';

// redux actions
import {switchContent} from '../../store/questionnaire.slice';

// services & config
import setAccessibilityResponder from '../../services/setAccessibilityResponder';
import translate from '../../services/localization';
import {theme} from '../../config';

// custom components
import QuestionnaireItem from './questionnaireItem';
import BottomBar from './bottomBar';
import RenderHtml from "react-native-render-html";
import questionnaireAnalyzer from "../../services/questionnaireAnalyzer";
import exportService from "../../services/questionnaireAnalyzer";
import {ScrollIndicatorWrapper} from "../../components/shared";
import {sendQuestionnaireResponse, reset, resetAnswers} from "../../store/sharedActions";
import NetInfo from "@react-native-community/netinfo";
import {ConfirmDialog, Dialog} from "react-native-simple-dialogs";
import {Button, IconButton, MD3Colors} from "react-native-paper";
import DeviceInfo from "react-native-device-info";
import {markdownToHtml} from "../../services/utils";
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component:
 * renders the questionnaireModal and the contents of the questionnaire
 ***********************************************************************************************/
function QuestionnaireModal({ nav = null }) {
    const dispatch = useDispatch();

    // creating references
    const scrollViewRef = useRef();
    const modalTitleRef = useRef();

    const [dialogCancelVisible, setDialogCancelVisible] = useState();
    const [headerShown, setHeaderShown] = useState(false);

    // setting defaults
    let scrollOffset = 0;

    const {
        pageIndex, categoryIndex, categories, itemMap,
        FHIRmetadata: metadata
    } = useSelector(
        (state) => state.Questionnaire,
    );

    const {subjectId} = useSelector(
        (state) => state.User,
    );

    // show the modal if the currently chosen categoryIndex is valid (i.e. > -1)
    const modalVisible = categoryIndex > -1;

    const {width} = useWindowDimensions();

    const [isKeyboardVisible, setKeyboardVisible] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            (e) => {
                setKeyboardVisible(true); // or some other action
                setKeyboardHeight(e.endCoordinates.height);

            }
        );
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardVisible(false); // or some other action
                setKeyboardHeight(0);

            }
        );

        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, []);


    useEffect(() => {
        setAccessibilityResponder(modalTitleRef);
    });


    /**
     * handles the scroll-event of the scrollView
     * @param  {object} event scroll event
     */
    const handleOnScroll = (event) => {
        // just sets the current scrollOffset
        scrollOffset = event.nativeEvent.contentOffset.y;
    };

    /**
     * @param  {{ y: number, animated: boolean }} element UI element that RNModal will scroll to (for example if the software-keyboard is shown)
     */
    const handleScrollTo = (element) => {
        // scrolls to the given element if the scrollView is currently active
        scrollViewRef?.current?.scrollTo({...element, animated: true});
    };

    const hideModalHandle = () => {
        dispatch(switchContent({categoryIndex: -1, pageIndex: 0}));
    };


    // check whether the current page has been completely answered
    const completed =
        itemMap?.[categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId]?.done;

    const required =
        itemMap?.[categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId]?.required;

    const skippable =
        itemMap?.[categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId]?.fieldAnnotation?.includes('[skippable]');
    console.log('skippable');
    console.log(skippable);

    /**
     * handler for the 'forward' and 'confirm' buttons
     */
    const _handleForwardPress = () => {
        setAccessibilityResponder(modalTitleRef);
        // skip questions whose dependencies are not
        let index = pageIndex;

        if (categories[categoryIndex].item[index - 1]?.fieldAnnotation?.includes('[stop]')) {
            hideModalHandle();
        }

        let submitSingle = categories[categoryIndex].item[index - 1]?.fieldAnnotation?.includes('[submitsingle]');
        console.log(categories[categoryIndex].item);
        console.log(index - 1);
        console.log('submitting single...')
        if (submitSingle) {
            NetInfo.fetch().then(state => {

                if (state.isConnected) {
                    let instrument = categories[categoryIndex].instrument_name;

                    let data = ''
                    categories[categoryIndex].item.forEach((item) => {
                        data += '<' + item.origCode + '>' + '' + '</' + item.origCode + '>';
                    });

                    console.log('submitting instrument...');

                    let response = exportService.createResponseJSON(
                        itemMap,
                        categories,
                        metadata,
                        instrument
                    );

                    console.log('response instrument')
                    console.log(response)

                    dispatch(
                        sendQuestionnaireResponse({
                            body: response,
                            instrument: instrument
                        }),
                    );
                } else {
                    Alert.alert(
                        translate('generic').errorNoInternetTitle,
                        translate('generic').errorNoInternet,
                        [
                            {
                                text: translate('generic').ok,
                            },
                        ],
                        {cancelable: false},
                    );
                }
            });
        } else {

            while (index < categories[categoryIndex].item.length) {
                if (
                    questionnaireAnalyzer.checkConditionsOfSingleItem(
                        categories[categoryIndex].item[index],
                        itemMap,
                    ) && !questionnaireAnalyzer.itemIsEmbedded(
                        categories[categoryIndex].item[index],
                        itemMap,
                    )
                ) {
                    dispatch(switchContent({pageIndex: index + 1}));
                    return handleScrollTo({y: 0, animated: false});
                }
                index += 1;
            }
            hideModalHandle();
        }
    }
    const handleForwardPress = () => {
        if (!completed && required) {
            let buttons = [
                {
                    text: translate('accessibility').questionnaire.warningAnswerNow,
                    style: 'cancel',
                }
            ];

            if (skippable) {
                let skipBtn = {
                    text: translate('accessibility').questionnaire.warningSkip,
                    style: 'default',
                    onPress: () => {
                        dispatch(_handleForwardPress);
                    }
                };
                buttons.push(skipBtn);
            }

            Alert.alert(
                translate('accessibility').questionnaire.warningNotAnsweredTitle,
                skippable ?
                    translate('accessibility').questionnaire.warningNotAnswered :
                    translate('accessibility').questionnaire.requiredNotAnswered,
                buttons,
                {cancelable: false},
            );
        } else {
            _handleForwardPress();
        }
    };


    function extractCopyrightContent(str: string): string | null {
        const regex = /\[copyright=(.*?)\]/;
        const match = regex.exec(str);
        if (match) {
            return match[1];
        }
        return null;
    }

    /**
     * renders the content based on the currently chosen category
     */

    let copyrightNotice = extractCopyrightContent(categories?.[categoryIndex]?.item[pageIndex - 1]?.fieldAnnotation);

    return (
        <RNModal
            avoidKeyboard={true}
            propagateSwipe
            coverScreen={true}
            // backdropOpacity={0.9}
            style={[localStyle.modal, {marginTop: isKeyboardVisible && Platform.OS === "ios" ? 0 : 0}]}
            scrollTo={handleScrollTo}
            scrollOffset={scrollOffset}
            isVisible={modalVisible}
            onModalWillShow={() => {
                // nav?.setOptions({ headerShown: false });
            }}
            onModalWillHide={() => {
                // nav?.setOptions({ headerShown: true });
            }}
            testID="QuestionnaireModal"
        >
            {/* renders the content of the page */}
            {modalVisible && (
                <View style={{height: '100%'}}>



                    <Dialog
                        visible={dialogCancelVisible}
                        title={"Beantwortung abbrechen"}
                        onTouchOutside={() => {}}
                        dialogStyle={{width: (Platform.OS === 'web' && isMobile ? '95%' : 800), alignSelf: 'center'}}
                    >
                        <View>
                            <Text style={{ marginBottom: 20 }}>Wollen Sie wirklich mit der Beantwortung des Fragebogens abbrechen?</Text>
                            <View style={{ flexDirection:  Platform.OS === 'web' && isMobile ? 'column' : 'row'  }}>
                                    <Button
                                        mode="outlined"
                                        onPress={() => {
                                            setDialogCancelVisible(false)
                                            if (categories[categoryIndex].item[0]?.fieldAnnotation.includes('[buttonSurvey')) {
                                                Alert.alert(
                                                    "Befragung beenden",
                                                    "Wenn Sie die Befragung jetzt beenden, werden Ihre bisherigen Antworten nicht gespeichert. Möchten Sie die Befragung wirklich beenden?",
                                                    [
                                                        {
                                                            text: "Eingabe fortfahren",
                                                            style: 'cancel',
                                                            onPress: () => {
                                                            }
                                                        },
                                                        {
                                                            text: "Beenden",
                                                            style: 'default',
                                                            onPress: () => {
                                                                // TODO reset given answer
                                                                hideModalHandle()
                                                                dispatch(resetAnswers());
                                                            },
                                                        }
                                                    ],
                                                    {cancelable: false},
                                                );
                                            } else {
                                                hideModalHandle()
                                            }
                                        }}
                                        style={{ marginRight: 5, marginBottom: 5 }}
                                    >
                                        Fragebogen schließen
                                    </Button>
                                    <Button
                                        mode="contained"
                                        onPress={() => {
                                            setDialogCancelVisible(false)
                                        }}
                                        style={{ marginRight: 5 }}
                                    >
                                        Beantworten fortfahren
                                    </Button>
                            </View>
                        </View>
                    </Dialog>





                    <View
                        style={[localStyle.content, { height: Dimensions.get('window').height - 90 - keyboardHeight }]}>
                        <View style={localStyle.titleWrapper}>
                            <Text
                                style={[localStyle.modalTitle, {flexGrow: 1,
                                    flexShrink: 0,
                                    flexBasis: 100}]}
                                ref={modalTitleRef}
                                accessibilityRole={translate('accessibility').types.header}
                            >{`${categories[categoryIndex].text}`}</Text>

                            <IconButton
                                icon="close"
                                iconColor={'black'}
                                size={23}
                                onPress={() => {
                                    setDialogCancelVisible(true)
                                }}
                                containerColor={theme.colors.secondary }
                                style={{ flexGrow: 0,
                                    flexShrink: 1,
                                    flexBasis: 'auto'}}
                                mode={"contained"}
                            />
                        </View>

                        <ScrollIndicatorWrapper
                            rescroll={pageIndex}
                            smallStepScroll={400}
                            ref={scrollViewRef}
                            onScroll={handleOnScroll}
                        >
                            {/*<ScrollView*/}
                            {/*    ref={scrollViewRef}*/}
                            {/*    onScroll={handleOnScroll}*/}
                            {/*    // scrollEventThrottle={16}*/}
                            {/*>*/}
                            <View style={{paddingLeft: 10, paddingRight: 10}}>
                                {copyrightNotice && <Text style={{ color: theme.colors.accent1 }}>{copyrightNotice}</Text>}

                                {categories?.[categoryIndex]?.item[pageIndex - 1]?.sectionHeader &&

                                    <RenderHtml
                                        contentWidth={width}
                                        source={{
                                            html: '<div style="font-weight: bold; font-size: ' + (DeviceInfo.isTablet() ? '1.75em' : '1.2em') + '">' +
                                                markdownToHtml(categories[categoryIndex].item[pageIndex - 1].sectionHeader)
                                                + '</div>'
                                        }}
                                    />
                                }
                                <QuestionnaireItem
                                    handleForwardPress={handleForwardPress}
                                    item={categories?.[categoryIndex]?.item[pageIndex - 1]}
                                    key={categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId}
                                    testID={`QuestionnaireItem_${
                                        categories?.[categoryIndex]?.item[pageIndex - 1]?.linkId
                                    }`}
                                />
                            </View>
                            {/*</ScrollView>*/}

                        </ScrollIndicatorWrapper>
                    </View>

                    {/* renders the bottom bar with the buttons to switch between
              questions*/}
                        <BottomBar
                            modalTitleRef={modalTitleRef}
                            handleScrollTo={handleScrollTo}
                            handleForwardPress={handleForwardPress}
                            hideModal={hideModalHandle}
                        />
                </View>
            )}
            {/* empty View in case the modal is hidden */}
            {!modalVisible && <View/>}
        </RNModal>
    );
}

/***********************************************************************************************
 styles
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    modal: {
        // justifyContent: 'flex-end',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        borderRadius: 10,
        width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
        alignSelf:'center'
    },

    titleWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 12,
        alignItems: 'center',
        width: I18nManager.isRTL ? '100%' : 'auto',

        paddingTop: Platform.OS === "ios" ? 50 : 10,
        paddingStart: 15,

        borderColor: theme.colors.primary,
        borderBottomWidth: 1,

        backgroundColor: theme.colors.primary_very_light
    },

    modalTitle: {
        fontSize: 24,
        paddingTop: 10,
        ...theme.fonts.header1,
        color: theme.values.defaultModalTitleColor,
    },

    content: {
        backgroundColor: theme.values.defaultModalContentBackgroundColor,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        ...(I18nManager.isRTL && {
            alignItems: 'flex-start',
        }),
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default QuestionnaireModal;
