import * as React from "react";
import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {theme} from "../../config";

const DashboardButton = ({text, onPress, solid = false}) => (

    <TouchableOpacity
        onPress={onPress}
        style={[styles.mainButton, solid ? styles.solidButton : null]}
    >

        <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
);

const styles = StyleSheet.create({

    mainButton: {
        width: '95%',
        height: 100,

        flex: 1,
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,

        borderRadius: 34,
        borderWidth: 4,
        borderColor: theme.colors.primary,
    },
    solidButton: {
        backgroundColor: theme.colors.primary
    },
    buttonText: {
        fontSize: 22,
        fontWeight: 'bold',
        marginTop: 5,
        textAlign: "center"
    },
});

export default DashboardButton;
