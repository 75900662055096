import defaultConfig from './appConfig';
import devConfig from './devConfig';

export { default as theme } from './theme';

// TODO: this DEV shouldn't be here, but it doesn't recognize __DEV__ otherwise
const __DEV__ = true;

export const appConfig = {
  ...defaultConfig,
  ...devConfig,
  baseURI: __DEV__ ? devConfig.baseUri : defaultConfig.baseUri,
  bleManager: null,
};
