// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

// this file provides methods concerning the communication with the backend before the user
// is logged in.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import {appConfig} from '../../config';
import encrypt from '../encryption';
import endpoints from './endpoints';

/***********************************************************************************************
 support functions
 ***********************************************************************************************/

/**
 * creates the Bearer token
 * @param  {string} [token] access token
 */
const createAuthorizationToken = (token) => {
    if (token) {
        console.log('createAuthorizataionToken');
        return `Bearer ${token}`;
    }
};

/**
 * generates the encapsuled message that will be transmitted to the server.
 * also triggers the encryption
 * @param  {string} subjectId
 * @param  {string} type type of the message
 * @param  {object} body body to encrypt
 */
const generateEncapsuledMessage = (subjectId, type, certString, body = {}) => {
    const msg = {
        type,
        data: {
            subjectId,
        },
    };
    if (body) msg.data.body = body;

    const encryptedMsg = encrypt(msg, certString);

    // console output
    if (appConfig.logEncryptedResponse) {
        console.log('THE ENCRYPTED QUESTIONNAIRE-RESPONSE:\n', encryptedMsg);
    }

    return {payload: encryptedMsg};
};

/***********************************************************************************************
 clients
 ***********************************************************************************************/

/***********************************************************************************************
 guest client functions
 ***********************************************************************************************/

/**
 * @param  {string} subjectId the id used to identify the user
 */
const login = async (subjectId) => {
    console.log('rest.js:login');
    console.log(endpoints.login + subjectId);
    const response = await fetch(endpoints.login + subjectId);
    console.log('await done');
    const body = await response.json();
    console.log('ok 3');
    console.log(body);
    if (response.ok) {
        return body;
    }
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    console.log(err);
    throw err;
};

/**
 * procures the list of languages
 */
const getLanguages = async () => {
    const response = await fetch(endpoints.getLanguages, {
        headers: {
            Accept: 'application/json',
        },
    });
    const body = await response.json();
    if (response.ok) {
        return body;
    }
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    throw err;
};

/***********************************************************************************************
 logged in client functions
 ***********************************************************************************************/

// user handling
/*-----------------------------------------------------------------------------------*/

/**
 * gets the subjectId and calls the getUser-endpoint
 */
const getUserUpdate = async (subjectId) => {
    console.log(endpoints.getUser + subjectId);
    const response = await fetch(endpoints.getUser + subjectId);
    console.log('response');
    console.log(response);
    const body = await response.json();
    console.log('body');
    console.log(body);
    if (response.ok) {
        return body;
    }
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    throw err;
};

// language
/*-----------------------------------------------------------------------------------*/

/**
 * updates the backend with the chosen language
 * @param  {string} subjectId string identifying the user
 * @param  {string} languageCode the language code
 */
const updateLanguageCode = async (subjectId, languageCode) => {
    const response = await fetch(endpoints.updateLanguage + subjectId, {
        method: 'POST',
        body: JSON.stringify({
            language: languageCode,
        }),
        headers: {
            Authorization: createAuthorizationToken(subjectId),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }
    const body = await response.json();
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    throw err;
};

// push
/*-----------------------------------------------------------------------------------*/

/**
 * updates the backend with the current FCM token
 * @param  {string} subjectId string identifying the user
 * @param  {string} token the token
 */
const updateDeviceToken = async (subjectId, token) => {
    const response = await fetch(endpoints.updateToken + subjectId, {
        method: 'POST',
        body: JSON.stringify({token}),
        headers: {
            Authorization: createAuthorizationToken(subjectId),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }
    const body = await response.json();
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    throw err;
};

// reports
/*-----------------------------------------------------------------------------------*/

/**
 * sends out the encapsuled message
 * @param  {string} subjectId string identifying the user
 */
const sendReport = async (subjectId, certString) => {
    const params = new URLSearchParams();
    params.append('subjectId', subjectId);
    params.append('type', 'report');
    params.append(
        'updateValues',
        JSON.stringify({
            [appConfig.defaultReportAttribute]: true,
        }),
    );

    const response = await fetch(`${endpoints.report}?${params.toString()}`, {
        method: 'POST',
        body: JSON.stringify(
            generateEncapsuledMessage(subjectId, 'report', certString),
        ),
        headers: {
            Authorization: createAuthorizationToken(subjectId),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
    const body = await response.json();
    if (response.ok) {
        return body;
    }
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    throw err;
};

// questionnaires
/*-----------------------------------------------------------------------------------*/

/**
 *
 * @param  {object}  body questionnaire response
 * @param  {Object.<string, boolean>} triggerMap trigger that might be set if the rules are met
 * @param  {string}  subjectId string identifying the user
 * @param  {string}  surveyId string identifying the current questionnaire
 * @param  {string}  instanceId instance of the current questionnaire
 * @param  {string}  certString the certificate containing the public key with which the response shall be encrypted
 */
const sendQuestionnaire = async (
    body,
    triggerMap,
    subjectId,
    surveyId,
    instanceId,
    certString,
) => {
    const queryParams = new URLSearchParams();
    queryParams.append('type', 'questionnaire_response');
    queryParams.append('id', subjectId);
    queryParams.append('subjectId', subjectId);
    queryParams.append('surveyId', surveyId);
    queryParams.append('instanceId', instanceId);
    queryParams.append('updateValues', JSON.stringify({...triggerMap}));

    const type = 'questionnaire_response';
    const msg = {
        type,
        data: {
            subjectId,
        },
    };
    if (body) msg.data.body = body;

    console.log('ok1');
    console.log(typeof msg);
    console.log(msg);

    const response = await fetch(
        `${endpoints.sendQuestionnaire}?${queryParams.toString()}`,
        {
            method: 'post',
            body: JSON.stringify(msg),
            headers: {
                Authorization: createAuthorizationToken(subjectId),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    );
    console.log(response);
    console.log(typeof response);
    const responseBody = await response.text(); // TODO MJ here was .json() but that does not work for some reason / please check
    console.log(responseBody);
    console.log(typeof responseBody);

    console.log('ok2');
    if (response.ok) {
        return responseBody;
    }
    const err = new Error(responseBody.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = responseBody.errorCode;
    err.code = response.status;
    throw err;
};

/**
 * procures the questionnaire from the backend
 * @param  {string} questionnaireId id of the questionnaire that the user is supposed to fill out
 */
const getBaseQuestionnaire = async (questionnaireId, subjectId, langCode) => {
    const response = await fetch(`${endpoints.getQuestionnaire}/${langCode}`, {
        method: 'POST',
        headers: {
            Authorization: createAuthorizationToken(subjectId),
            Accept: 'application/json',
        },
    });
    console.log('fetching questionnaire....');
    const body = await response.json();
    if (response.ok) {
        return body;
    }
    const err = new Error(body.errorMessage || 'NETWORK REQUEST FAILED');
    err.name = body.errorCode;
    err.code = response.status;
    throw err;
};

const importRecord = async (subjectId, instrument, itemString, file, fileField) => {

    const formData = new FormData();

    if (file) {
        formData.append('file', {
            uri: 'file://' + file,
            type: 'application/zip', // Change the type according to the file being uploaded
            name: 'data.zip', // Change the name of the file as needed
        });
        formData.append('fileField', fileField);
    }
    console.log('submitting subject data...', file ? 'with file...' + file : '');

    formData.append('subjectId', subjectId);
    formData.append('instrument', instrument);
    formData.append('itemString', itemString);

    let body, response;
    try {
         response = await fetch(`${endpoints.importRecord}/${instrument}?r=${Math.random()}`, {
            method: 'POST',
            headers: {
                Authorization: createAuthorizationToken(subjectId),
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        body = await response.json();
        if (response.ok) {
            console.log('Submitting subject data succeeded.', file ? 'with file...' + file : '')
            return body;
        }
    } catch(e) {
        console.log('NETWORK REQUEST FAILED1');
        console.log(e);
        console.log(e.message);
        console.log(e.name);
        console.log(e.stack);
        throw e;
    }
    console.log('Failed contacting the server');
    const err = new Error(body?.errorMessage || 'NETWORK REQUEST FAILED2');
    err.name = body?.errorCode;
    err.code = response?.status;
    console.log(err);
    throw err;
}

/***********************************************************************************************
 export
 ***********************************************************************************************/

export const loggedInClient = {
    sendReport,
    getBaseQuestionnaire,
    sendQuestionnaire,
    getUserUpdate,
    updateDeviceToken,
    updateLanguageCode,
    getLanguages,
    importRecord
};

export const guestClient = {
    login,
    getLanguages,
};
