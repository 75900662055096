// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React from 'react';
import {View, StyleSheet, Text, Platform} from 'react-native';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// custom components
import { Banner } from '../components/shared';

// services & config
import translate from '../services/localization';
import { appConfig, theme } from '../config';
import WalkthroughView from "../components/checkIn/walkthroughView";
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function WalkthroughScreen({ navigation }) {
  return (
    <View>
      <View style={localStyle.wrapper}>
        {/* banner */}
        <Banner
          nav={navigation}
          title={translate('about').intro.title}
        />
        {/* content */}
        <WalkthroughView
        onDone={() => {navigation.goBack() }}
        onSkip={() => {navigation.goBack() }}
        />
      </View>
    </View>
  );
}

WalkthroughScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
localStyle
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    height: '100%',
    width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  }
});

/***********************************************************************************************
export
***********************************************************************************************/

export default WalkthroughScreen;
