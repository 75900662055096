import React from 'react';
import {Platform, Text, useWindowDimensions} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { itemPropType } from '../../../propTypes';

// components
import { CheckBox } from '@rneui/themed';

// redux actions
import { setAnswer } from '../../../store/questionnaire.slice';

// services & config
import { theme } from '../../../config';
import translate from '../../../services/localization';

import SharedStyles, { calculateIndent } from './sharedStyles';
import RenderHtml from "react-native-render-html";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";
import {markdownToHtml, stripTags} from "../../../services/utils";
import DeviceInfo from "react-native-device-info";

/***********************************************************************************************
 * renders a questionnaire item as boolean input
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/
export default function BooleanInput({ item }) {
  const dispatch = useDispatch();

  // get current value from State
  const currentAnswer = useSelector(
    (state) =>
      state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueBoolean,
  );

  const { width } = useWindowDimensions();
  const source = {
    html: '<div style="font-weight: bold; font-size: ' + (DeviceInfo.isTablet()? '1.75em' : '1.2em') + '">' + markdownToHtml(item.text) + '</div>'
  };

  return (
    <>
      {Platform.OS === 'web' ? (<div
            dangerouslySetInnerHTML={{__html: source.html}}
        />) :
            (<RenderHtml
                contentWidth={width}
                source={source}
            />)
        }

        { !!item.fieldAnnotation && !! stripTags(item.fieldAnnotation) &&
        <ModalInfo
            infoText={stripTags(item.fieldAnnotation)}
        />
      }

      <CheckBox
        title={translate('generic').yes}
        uncheckedIcon="circle-o"
        checkedIcon="dot-circle-o"
        checkedColor={theme.colors.primary}
        uncheckedColor={theme.colors.accent1}
        checked={currentAnswer}
        onPress={() =>
          dispatch(
            setAnswer({
              linkId: item.linkId,
              answer: { valueBoolean: true },
            }),
          )
        }
        key={`${item.linkId}_true`}
        containerStyle={{
          ...SharedStyles.choice,
          marginLeft: calculateIndent(item.linkId),
        }}
        textStyle={SharedStyles.choiceText}
        testID="BooleanInput.true"
      />
      <CheckBox
        uncheckedIcon="circle-o"
        checkedIcon="dot-circle-o"
        title={translate('generic').no}
        checkedColor={theme.colors.primary}
        uncheckedColor={theme.colors.accent1}
        checked={currentAnswer === false}
        onPress={() =>
          dispatch(
            setAnswer({
              linkId: item.linkId,
              answer: { valueBoolean: false },
            }),
          )
        }
        key={`${item.linkId}_false`}
        containerStyle={{
          ...SharedStyles.choice,
          marginLeft: calculateIndent(item.linkId),
        }}
        textStyle={SharedStyles.choiceText}
        testID="BooleanInput.false"
      />
    </>
  );
}


BooleanInput.propTypes = {
  item: PropTypes.shape(itemPropType).isRequired,
};
