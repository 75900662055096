import React, {useEffect, useState} from 'react';
import {Alert, Linking, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import VersionCheck from '../components/shared/VersionCheck';

import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// custom components
import {Banner, ScrollIndicatorWrapper, Spinner} from '../components/shared';
import {CheckInListView, CheckInWelcomeText,} from '../components/checkIn';

// redux actions
import {getLanguages} from '../store/globals.slice';
import {updateFCMToken, updateUser} from '../store/user.slice';
import {deleteQuestionnaire} from '../store/questionnaire.slice';
import {sendQuestionnaireResponse, sendReport} from '../store/sharedActions';

// services & config
import translate from '../services/localization';
import {appConfig, theme} from '../config';
import exportService from '../services/questionnaireAnalyzer';
import i18n from "i18n-js";
import endpoints from "../services/rest/endpoints";
import { ListItem, Button } from '@rneui/themed';
// import PushNotification from '../components/shared/PushNotification';
// import PushNotificationIOS, {PushNotificationIOSStatic} from "@react-native-community/push-notification-ios";
import {check, request, RESULTS} from "react-native-permissions";
import DeviceInfo from "react-native-device-info";
import {isMobile} from "react-device-detect";

/***********************************************************************************************
 * component
 * renders the checkIn-screen, which is basically the core of the app
 * displays information about the current or next questionnaire
 * contains a banner element to navigate to the survey screen
 * as well buttons to send out the questionnaire or a special report
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function CheckInScreen({navigation}) {
    const dispatch = useDispatch();

    // get data from global state
    const {
        error,
        loading,
        BLEBatteryLevel,
        BLEdevice,
        BLEdeviceState,
        BLErssi,
        BLEbufferSize
    } = useSelector((state) => state.Globals);
    const last_ble_data_received = useSelector((state) => state.User.last_ble_data_received);

    const [deviceName, setDeviceName] = useState(null);
    const [deviceState, setDeviceState] = useState(null);
    const [deviceBatteryLevel, setDeviceBatteryLevel] = useState(null);
    const [bufferSize, setBufferSize] = useState(null);
    const [deviceRSSI, setDeviceRSSI] = useState(null);
    const [showEnableNotifications, setShowEnableNotifications] = useState(false);
    const [lastDataReceived, setLastDataReceived] = useState(last_ble_data_received);
    const [showFullSubjectId, setShowFullSubjectId] = useState(false);

    useEffect(() => {
        setDeviceBatteryLevel(BLEBatteryLevel);
    }, [BLEBatteryLevel]);
    useEffect(() => {
        setDeviceName(BLEdevice?.name);
    }, [BLEdevice]);
    useEffect(() => {
        setDeviceState(BLEdeviceState);
    }, [BLEdeviceState]);
    useEffect(() => {
        setDeviceRSSI(BLErssi);
    }, [BLErssi]);
    useEffect(() => {
        setBufferSize(BLEbufferSize);
    }, [BLEbufferSize]);
    useEffect(() => {
        setLastDataReceived(last_ble_data_received);
    }, [last_ble_data_received]);


    useEffect(() => {
        navigation.addListener('focus', () => {
            checkApplicationPermission().then(() => {
                // PushNotification.localNotification({
                //     channelId: "umfragetoolneu", // (required) channelId, if the channel doesn't exist, notification will not trigger.
                //     /* iOS and Android properties */
                //     id: 888, // (optional) Valid unique 32 bit integer specified as string. default: Autogenerated Unique ID
                //     title: "Test-Benachrichtigung", // (optional)
                //     message: "Die App schickt Ihnen bei Bedarf Benachrichtungen wie diese. Sie können nun diese Benachrichtigung schließen.", // (required)
                //     playSound: true,
                //     largeIcon: 'push_notification_icon',
                //     smallIcon: 'push_notification_icon',
                //     bigLargeIcon: 'push_notification_icon',
                // });
            });
        });
    }, [navigation]);

    const checkApplicationPermission = async () => {
        // if (Platform.OS === 'android') {
        //     try {
        //         console.log('asking for permissions android')

        //         if (Platform.Version >= 33) {
        //             let result =
        //                 await check('android.permission.POST_NOTIFICATIONS');

        //             if (result === RESULTS.DENIED) {
        //                 console.log('denied', result) // FIXME it seems like RESULTS.DENIED is being returned even when Android refuses to ask again the user, this is not expected behavior.
        //                 Alert.alert(
        //                     "Benachrichtigungen erlauben",
        //                     "Wir möchten Ihnen gerne Benachrichtigungen schicken, um Sie auf bevorstehende Fragebögen und anstehende Ereignisse im Laufe der Studie an der Sie teilnehmen erinnern. Bitte erlauben Sie im nächsten Schritt den Empfang von Benachrichtigungen.",
        //                     [
        //                         {
        //                             text: translate('generic').ok,
        //                             onPress: () => {
        //                                 request(
        //                                     'android.permission.POST_NOTIFICATIONS'
        //                                 );
        //                                 request(
        //                                     'android.permission.SCHEDULE_EXACT_ALARM',
        //                                 );
        //                                 request(
        //                                     'android.permission.USE_EXACT_ALARM',
        //                                 );
        //                             }
        //                         },
        //                     ],
        //                     {
        //                         cancelable: false
        //                     },
        //                 );
        //             }


        //             console.log('requested');
        //         }

        //         PushNotification.createChannel(
        //             {
        //                 channelId: "umfragetoolimportant",
        //                 channelName: "UKB-Umfragetool/wichtig",
        //                 channelDescription: "Wichtige Benachrichtigungen vom Umfragetool UK Bonn",
        //                 playSound: true,
        //                 soundName: 'alert.mp3',
        //                 vibrate: true
        //             },
        //             (created) => console.log(`createChannel returned '${created}'`) // (optional) callback returns whether the channel was created, false means it already existed.
        //         );
        //         PushNotification.createChannel(
        //             {
        //                 channelId: "umfragetool",
        //                 channelName: "Umfragetool UK Bonn",
        //                 channelDescription: "Benachrichtigungen vom Umfragetool UK Bonn",
        //                 playSound: true,
        //                 vibrate: false
        //             },
        //             (created) => console.log(`createChannel returned '${created}'`) // (optional) callback returns whether the channel was created, false means it already existed.
        //         );

        //     } catch (error) {
        //     }
        // } else if (Platform.OS === 'ios') {
        //     console.log('asking for permissions iOS')
        //     await PushNotificationIOS.checkPermissions((permissions) => {
        //         console.log('checkPermissions output');
        //         console.log(permissions);
        //         if (permissions.authorizationStatus === PushNotificationIOS.AuthorizationStatus.UNAuthorizationStatusNotDetermined) {

        //             Alert.alert(
        //                 "Benachrichtigungen erlauben",
        //                 "Wir möchten Ihnen gerne Benachrichtigungen schicken, um Sie auf bevorstehende Fragebögen und anstehende Ereignisse im Laufe der Studie an der Sie teilnehmen erinnern. Bitte erlauben Sie im nächsten Schritt den Empfang von Benachrichtigungen.",
        //                 [
        //                     {
        //                         text: translate('generic').ok,
        //                         onPress: () => {
        //                             PushNotificationIOS.requestPermissions();
        //                             PushNotificationIOS.checkPermissions((permissions) => {
        //                                 console.log('checkPermissions output');
        //                                 console.log(permissions);
        //                             });
        //                         }
        //                     },
        //                 ],
        //                 {
        //                     cancelable: false
        //                 },
        //             );

        //         }
        //     });
        // }
        // TODO: check if aboce commented out code is necessary for web
        return true;
    };

    const {
        status,
        subjectId,
        certificate,
        firstTime,
        due_date,
        start_date,
        current_questionnaire_id,
        additional_iterations_left,
        show_subject_id,
        welcome_title,
        welcome_text
    } = useSelector((state) => state.User);

    const {
        itemMap,
        categories,
        FHIRmetadata: metadata,
    } = useSelector((state) => state.Questionnaire);

    // trigger user update when app is opened and user was logged in
    useEffect(() => {
        if (subjectId) {
            dispatch(updateUser(subjectId));
            if (appConfig.connectToFCM) {
                dispatch(updateFCMToken(subjectId));
            }
            dispatch(getLanguages());
        }
    }, [dispatch, subjectId]);

    // check if the currently persisted questionnaire is outdated
    // if so, alert user and delete data
    useEffect(() => {
        if (
            !!metadata &&
            current_questionnaire_id !== `${metadata.url}|${metadata.version}`
        ) {
            Alert.alert(
                translate('generic').info,
                translate('generic').infoRemoval,
                [
                    {
                        text: translate('generic').ok,
                        onPress: () => dispatch(deleteQuestionnaire()),
                    },
                ],
                {cancelable: false},
            );
        }
    }, [current_questionnaire_id, metadata, dispatch]);

    const noNewQuestionnaireAvailableYet = new Date() < new Date(start_date);
    const categoriesLoaded = categories && categories.length > 0;

    // check if at least one category of the questionnaire has been started
    const started = categories?.some(
        (category) => itemMap[category.linkId].started,
    );
    // check if all categories of the current questionnaire have been completed
    const done = categories?.every((category) => itemMap[category.linkId].done);

    // #################### event & button handlers ####################

    /**
     * handle submission of questionnaire response
     */
    const handleSubmit = () => {
        Alert.alert(
            translate('generic').info,
            translate('survey').sendFinishedMessage,
            [
                {
                    text: translate('survey').sendFinished,
                    onPress: () =>
                        dispatch(
                            sendQuestionnaireResponse({
                                body: exportService.createResponseJSON(
                                    itemMap,
                                    categories,
                                    metadata,
                                ),
                            }),
                        ),
                },
                {
                    text: translate('generic').abort,
                    style: 'cancel',
                },
            ],
            {cancelable: false},
        );
    };

    /**
     * handle submission of special report
     */
    const handleReport = () => {
        if (subjectId && additional_iterations_left) {
            // shows a dialog telling the user that he/she already send out a report
            Alert.alert(
                translate('generic').info,
                translate('generic').reportWhileInIteratedMode,
                [
                    {
                        text: translate('generic').ok,
                    },
                ],
                {cancelable: false},
            );
        } else if (!noNewQuestionnaireAvailableYet) {
            // dialog telling the user to use the current questionnaire
            Alert.alert(
                translate('generic').info,
                translate('generic').reportWhileQuestionnaire,
                [
                    {
                        text: translate('generic').ok,
                    },
                ],
                {cancelable: false},
            );
        } else {
            Alert.alert(
                translate('reporting').symptoms_header,
                translate('reporting').symptoms_question,
                [
                    {
                        text: translate('reporting').symptoms_yes,
                        onPress: () => {
                            // send out the report
                            dispatch(sendReport({subjectId, certificate}));
                        },
                    },
                    {
                        text: translate('reporting').symptoms_no,
                        style: 'cancel',
                    },
                ],
                {cancelable: false},
            );
        }
    };

    return loading ? (
        <Spinner/>
    ) : (
        <View style={localStyle.wrapper} testID="CheckInScreen">
            {/* banner at the top */}
            <Banner
                nav={navigation}
                title={translate('survey').titleCheckIn}
                subTitle={translate('survey').subTitleCheckIn}
                updateUser={() => dispatch(updateUser(subjectId))}
                isCheckIn
                noWayBack
                noRefresh={status === 'off-study'}
            />

            {/*  center content */}
            {subjectId && (
                <View style={[localStyle.flexi, localStyle.wrapper]}>
                    <ScrollIndicatorWrapper>
                        <View style={localStyle.wrapper}>

                            {show_subject_id && (
                            <TouchableOpacity onPress={() => setShowFullSubjectId(!showFullSubjectId)}>
                                <View style={{ backgroundColor: '#dddddd', marginTop: 10, padding: 10 }}>
                                    <Text style={{textAlign: 'center', fontWeight: 'bold'}}>Probanden-ID:</Text>
                                    <Text style={{textAlign: 'center'}}>{showFullSubjectId
                                        ? subjectId
                                        : subjectId.substring(0, 10) + '...'}</Text>
                                </View>
                            </TouchableOpacity>
                            )}

                            {/* welcome text with due-date information */}
                            <CheckInWelcomeText
                                error={error}
                                status={status}
                                noNewQuestionnaireAvailableYet={noNewQuestionnaireAvailableYet}
                                firstTime={firstTime}
                                dueDate={due_date}
                                startDate={start_date}
                                categoriesLoaded={categoriesLoaded}
                                welcomeTitle={welcome_title}
                                welcomeText={welcome_text}
                            />

                            {/* renders the listview item representing the questionnaire */}
                            {!noNewQuestionnaireAvailableYet && status !== 'off-study' && (
                                <CheckInListView
                                    firstTime={firstTime}
                                    navigation={navigation}
                                    dueDate={due_date}
                                    done={done}
                                    started={started}
                                />
                            )}

                            {/* renders the button at the bottom */}

                            {/*<CheckInTiles*/}
                            {/*  done={done}*/}
                            {/*  status={status}*/}
                            {/*  iterationsLeft={additional_iterations_left}*/}
                            {/*  categoriesLoaded={categoriesLoaded}*/}
                            {/*  noNewQuestionnaireAvailableYet={noNewQuestionnaireAvailableYet}*/}
                            {/*  sendReport={handleReport}*/}
                            {/*  deleteLocalDataAndLogout={() => {*/}
                            {/*    TODO */}
                            {/*  }}*/}
                            {/*  exportAndUploadQuestionnaireResponse={handleSubmit}*/}
                            {/*/>*/}
                        </View>
                        <Text style={{ marginTop: 10, textAlign: 'center' }}>
                            App-Entwicklung: Institut für Digitale Medizin,
                            Universitätsklinikum Bonn (UKB){'\n'}
                            &copy; 2024. Alle Rechte vorbehalten.
                        </Text>
                    </ScrollIndicatorWrapper>
                </View>
            )}
        </View>
    );
}

CheckInScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
        alignSelf: 'center',
        height: '100%',
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    },

    flexi: {
        flex: 1,
    },


    btTitle: {
        ...theme.fonts.header3,
        color: theme.values.defaultCheckInListViewTitleColor,
    },

    btSubTitle: {
        color: theme.colors.yes,
        ...theme.fonts.header3,
    },
    btSubTitleWarning: {
        color: theme.colors.no,
        ...theme.fonts.header3,
    },

});

export default CheckInScreen;
