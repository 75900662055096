// this is a mock Version Check return, because react-native-version-check is not supported on web

const currentVersion = "2.0";
const currentBuildNumber = "2.0";

function getCurrentVersion() {
    return currentVersion;
}

function getCurrentBuildNumber() {
    return currentBuildNumber;
}

function getCountry() {
    // TODO: find way to automate proper detection -> for now set to "DE"
    return "DE";
}

function getPackageName() {

}


const VersionCheck = {
    getCurrentVersion,
    getCurrentBuildNumber,
    getCountry,
    getPackageName
};

export default VersionCheck;